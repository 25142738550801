.flag-select__options {
	background: #ffffffb8 !important;
}

.flag-select__option:not(.flag-select__option--placeholder):hover,
.flag-select__option:not(.flag-select__option--placeholder):active {
	background: #eaeaeacb !important;
}

.flag-select__btn {
	color: white !important;
}

.flag-select__btn[aria-expanded="true"]:after {
	border-bottom: 5px solid #ffffff !important;
	border-top: 0 !important;
}

.flag-select__btn:after {
	border-top: 5px solid #ffffff !important;
	border-bottom: 0 !important;
}

.flag-select__options {
	right: 0 !important;
}

.language-select-div {
	width: 5vw;
	display: flex;
	margin-top: 7px;
	margin-left: 20px;
}

.flag-select__option__label {
	font-size: 1.2em !important;
}

@media screen and (max-width: 860px) {
	.language-select-div {
		width: 20vw;
		display: block;
		margin: auto;
	}
}
