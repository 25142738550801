.tooltip {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: rgb(76, 76, 76);
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	font-size: 18px;
	font-weight: 500;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
	top: 40px;
	left: -50%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}
