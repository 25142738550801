.project-timeline-container .timeline-grid h3 {
	font-weight: 400;
}

.project-timeline-container .timeline-grid {
	display: flex;
}

.project-timeline-container .ordinal-number {
	font-size: 1.7rem;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	margin-right: 0.5rem;
	font-weight: 700 !important;
}

.project-timeline-container .timeline-item-text {
	text-align: left;
	color: #666;
}

.MuiTimeline-root {
	border: 0 !important;
}

.timeline-control-buttons {
	margin: 0 5%;
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 768px) {
	.MuiTimelineOppositeContent-root {
		display: none !important;
	}
	.MuiTimelineItem-missingOppositeContent:before {
		display: none !important;
	}
	.MuiTimelineItem-alignAlternate:nth-child(even) {
		flex-direction: row !important;
	}
	.project-timeline-container .timeline-grid h3 {
		font-size: 1rem;
	}
	.project-timeline-container {
		padding-left: 5%;
	}
}
