.file-card-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.file-icon-container {
	display: grid;
	width: 30%;
	grid-template-columns: 1fr 5fr;
	justify-content: center;
	align-items: center;
	justify-self: flex-start;
	margin-right: 20%;
}

.file-card-container h3 {
	padding-left: 1rem;
}
