@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?f2f78f3a1c689a4e9156675152d1831f") format("truetype"),
url("./flaticon.woff?f2f78f3a1c689a4e9156675152d1831f") format("woff"),
url("./flaticon.woff2?f2f78f3a1c689a4e9156675152d1831f") format("woff2"),
url("./flaticon.eot?f2f78f3a1c689a4e9156675152d1831f#iefix") format("embedded-opentype"),
url("./flaticon.svg?f2f78f3a1c689a4e9156675152d1831f#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-3d-printer:before {
    content: "\f101";
}
.flaticon-ruler:before {
    content: "\f102";
}
.flaticon-graphic-design:before {
    content: "\f103";
}
.flaticon-measure:before {
    content: "\f104";
}
.flaticon-rubber:before {
    content: "\f105";
}
.flaticon-research:before {
    content: "\f106";
}
.flaticon-accounting:before {
    content: "\f107";
}
.flaticon-pin:before {
    content: "\f108";
}
.flaticon-phone-call:before {
    content: "\f109";
}
.flaticon-email:before {
    content: "\f10a";
}
.flaticon-pencil:before {
    content: "\f10b";
}
.flaticon-tools:before {
    content: "\f10c";
}
.flaticon-layers:before {
    content: "\f10d";
}
.flaticon-sun:before {
    content: "\f10e";
}
.flaticon-sun-1:before {
    content: "\f10f";
}
.flaticon-investor:before {
    content: "\f110";
}
.flaticon-settings:before {
    content: "\f111";
}
.flaticon-save-money:before {
    content: "\f112";
}
.flaticon-design:before {
    content: "\f113";
}
.flaticon-scale:before {
    content: "\f114";
}
.flaticon-project:before {
    content: "\f115";
}
.flaticon-close:before {
    content: "\f116";
}
.flaticon-magnifying-glass-search:before {
    content: "\f117";
}
.flaticon-gear:before {
    content: "\f118";
}
.flaticon-trash:before {
    content: "\f119";
}
.flaticon-check:before {
    content: "\f11a";
}
.flaticon-check-1:before {
    content: "\f11b";
}
.flaticon-upload-big-arrow:before {
    content: "\f11c";
}
