.image-container {
	width: 80%;
	max-width: 100%;
	overflow: hidden;
	margin: 5% auto;
}
.narrow {
	width: 100% !important;
}
.image-container img {
	width: 100%;
	height: auto;
	display: block;
}

.image-container .center {
	text-align: center !important;
	margin-top: 8px;
	font-weight: 500;
	font-size: smaller;
}

@media only screen and (max-width: 768px) {
	.image-container {
		width: 100%;
	}
}
