:root {
	--primary: #fff;
	--orange: #ff6b00;
}
/* test */
.btn {
	padding: 8px 18px;
	border-radius: 4px;
	outline: none;
	border: none;
	cursor: pointer;
	white-space: nowrap;
	font-size: 1.1rem;
	text-transform: capitalize;
}
.btn--primary {
	background-color: var(--orange);
	color: var(--primary);
	transition: all 0.3s ease-out;
	border: 1px solid var(--orange);
}

/* Commented code makes UI experience confusing :'D */

/* .btn--primary:hover {
  background-color: transparent;
  color: #fff;
} */

.btn--outline {
	background-color: transparent;
	color: #fff;
	padding: 8px 18px;
	border: 1px solid var(--orange);
	transition: all 0.3s ease-out;
}
/* .btn--outline:hover {
  background-color: var(--orange);
  color: var(--primary);
} */

.btn-medium {
	padding: 8px 20px;
	font-size: 18px;
}
.btn--large {
	padding: 12px 26px;
	font-size: 20px;
}

.btn--mobile {
	text-align: center;
	border-radius: 4px;
	width: 80%;
	text-decoration: none;
	font-size: 15px;
	background-color: transparent;
	color: #fff;
	padding: 14px 20px;
	border: 1px solid #fff;
	transition: all 0.3s ease-out;
}

.btn--wide {
	padding: 12px 64px;
	font-size: 20px;
}
.btn--outline:hover,
.btn--outline:active {
	transition: all 0.3s ease-out;
	background: #ff6a00be;
	color: #fff;
}
.btn--large:hover,
.btn-medium:hover,
.btn--mobile:hover,
.btn--large:active,
.btn-medium:active,
.btn--mobile:active {
	transition: all 0.3s ease-out;
	background: #fff;
	color: #242424;
}
.btn--wide:hover,
.btn--wide:active {
	color: #fff;
	background-color: #242424;
	transition: all 0.2s ease-out;
}
.orange {
	background-color: #ff6b00;
	color: #fff;
	border: none;
}
.blue {
	background-color: #276afb;
	color: #fff;
	border: none;
}
.red {
	background-color: #f00946;
	color: #fff;
	border: none;
}
.primary {
	background-color: #242424;
	color: #fff;
	border: none;
}
.primary:hover,
.primary:active {
	background-color: #fff;
	color: #242424;
	border: none;
}
.green {
	background-color: #25ce4a;
	color: #fff;
	border: none;
}
.green:hover,
.green:active {
	background-color: #242424;
}
.btn-link {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
}
.btn--colorWhite {
	color: var(--primary);
	font-weight: bold;
}
