@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap);
* {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
}

body {
	margin: 0;
	padding: 0;
}

html,
body {
	max-width: 100%;
	/* position sticky for header does not work with overflow-hidden */
	/* overflow-x: hidden; */
}

@font-face {
	font-family: Inter;
	src: url(/static/media/Inter-Regular.9cd7588f.ttf);
}

font-face {
	font-family: Roboto;
	src: url(/static/media/Roboto-Regular.a8d6ac03.ttf);
}

textarea {
	resize: none;
	width: 100%;
}

/* scale every text to 80% */

html {
	font-size: 80%;
}

.ReactModal__Overlay {
	z-index: 10;
}

.section-heading-font {
	font-family: Poppins, cursive;
	color: #f8802b;
	font-weight: 600;
	font-size: 2rem;
	margin: 2rem;
	text-transform: uppercase;
	text-align: left;
	margin-left: 0;
	letter-spacing: 0.02rem;
}

a {
	color: inherit;
}

html {
	scroll-behavior: smooth;
}

.other-pages {
	width: 90%;
}

@media only screen and (max-width: 500px) {
	.section-heading-font {
		font-size: 1.6rem;
		text-align: center;
	}
}

.footer-container {
	background-color: #1c2237;
	padding: 2rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 0;
	/* margin-top: 5rem; */
}

.footer-subscription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 15px;
	padding: 15px;
	color: #fff;
	min-height: 10.1vh;
}

.footer-subscription > p {
	font-family: "Trebuchet MS", Arial, sans-serif;
}

.footer-subscription-heading {
	margin-bottom: 14px;
	font-size: 24px;
}

.footer-subscription-text {
	margin-bottom: 24px;
	font-size: 20px;
}

.footer-input {
	padding: 6px 20px;
	border-radius: 2px;
	margin-right: 10px;
	outline: none;
	border: none;
	font-size: 18px;
	margin-bottom: 16px;
	border: 1px solid #fff;
}

.footer-links {
	width: 100%;
	max-width: 1000px;
	display: flex;
	justify-content: center;
	display: none;
}

.footer-link-wrapper {
	display: flex;
}

.footer-link-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 16px;
	text-align: center;
	width: 190px;
	box-sizing: border-box;
}

.footer-link-items h2 {
	margin-bottom: 16px;
}

.footer-link-items > h2 {
	color: #fff;
}

.footer-link-items a {
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
	color: #ff6b00;
	transition: 0.3s ease-out;
}

.footer-email-form h2 {
	margin-bottom: 2rem;
}

.footer-input::placeholder {
	color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
	color: #fff;
	font-size: 24px;
}

.social-media {
	max-width: 1000px;
	width: 100%;
}

.social-media-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 2px auto 0 auto;
}

.social-icons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
}

.social-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.footer-logo .footer-logo-div {
	background: url(/static/media/solar-logo-footer.2fbe155f.svg);
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.website-rights {
	color: #fff;
	margin-bottom: 16px;
	font-size: large;
}

@media screen and (max-width: 820px) {
	.footer-links {
		padding-top: 2rem;
	}

	.footer-input {
		width: 100%;
	}

	.footer-container .btn {
		width: 100%;
	}

	.footer-link-wrapper {
		flex-direction: column;
	}

	.social-media-wrap {
		flex-direction: column;
	}
}

@media only screen and (max-width: 768px) {
	.footer-link-items {
		width: 90%;
	}
}

:root {
	--primary: #fff;
	--orange: #ff6b00;
}
/* test */
.btn {
	padding: 8px 18px;
	border-radius: 4px;
	outline: none;
	border: none;
	cursor: pointer;
	white-space: nowrap;
	font-size: 1.1rem;
	text-transform: capitalize;
}
.btn--primary {
	background-color: var(--orange);
	color: var(--primary);
	transition: all 0.3s ease-out;
	border: 1px solid var(--orange);
}

/* Commented code makes UI experience confusing :'D */

/* .btn--primary:hover {
  background-color: transparent;
  color: #fff;
} */

.btn--outline {
	background-color: transparent;
	color: #fff;
	padding: 8px 18px;
	border: 1px solid var(--orange);
	transition: all 0.3s ease-out;
}
/* .btn--outline:hover {
  background-color: var(--orange);
  color: var(--primary);
} */

.btn-medium {
	padding: 8px 20px;
	font-size: 18px;
}
.btn--large {
	padding: 12px 26px;
	font-size: 20px;
}

.btn--mobile {
	text-align: center;
	border-radius: 4px;
	width: 80%;
	text-decoration: none;
	font-size: 15px;
	background-color: transparent;
	color: #fff;
	padding: 14px 20px;
	border: 1px solid #fff;
	transition: all 0.3s ease-out;
}

.btn--wide {
	padding: 12px 64px;
	font-size: 20px;
}
.btn--outline:hover,
.btn--outline:active {
	transition: all 0.3s ease-out;
	background: #ff6a00be;
	color: #fff;
}
.btn--large:hover,
.btn-medium:hover,
.btn--mobile:hover,
.btn--large:active,
.btn-medium:active,
.btn--mobile:active {
	transition: all 0.3s ease-out;
	background: #fff;
	color: #242424;
}
.btn--wide:hover,
.btn--wide:active {
	color: #fff;
	background-color: #242424;
	transition: all 0.2s ease-out;
}
.orange {
	background-color: #ff6b00;
	color: #fff;
	border: none;
}
.blue {
	background-color: #276afb;
	color: #fff;
	border: none;
}
.red {
	background-color: #f00946;
	color: #fff;
	border: none;
}
.primary {
	background-color: #242424;
	color: #fff;
	border: none;
}
.primary:hover,
.primary:active {
	background-color: #fff;
	color: #242424;
	border: none;
}
.green {
	background-color: #25ce4a;
	color: #fff;
	border: none;
}
.green:hover,
.green:active {
	background-color: #242424;
}
.btn-link {
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	padding: 8px 16px;
	height: 100%;
	width: 100%;
}
.btn--colorWhite {
	color: var(--primary);
	font-weight: bold;
}

.navbar {
	background: #1c223700;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 10; /*   prethodni (sa bc bojom nezavistan od hero sekcije) */
	/*   margin-bottom: 7rem;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 999; */
	/*Novi (Transparentni, predstavlja dio hero sekcije)*/
}

.navbar-absolute {
	position: absolute;
	width: 100vw;
	max-width: 100%;
	top: 0;
	left: 0;
	background: transparent
		linear-gradient(359deg, #00000000 0%, #00000000 21%, #0000006e 90%) 0% 0%
		no-repeat padding-box;
	/* height: 20vh; */
}

.navbar-container {
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;
}

.navbar-container .ReactFlagsSelect-module_selectOptionValue__vS99- {
	flex-direction: column;
}

.navbar-logo {
	margin-top: 0.5%;
}

.navbar-logo-div {
	background: url(/static/media/Roofko_white.5e142936.svg);
	width: 14rem;
	height: 5.2rem;

	/* height: 75%; */
	background-position: center;
	z-index: 2000;
	background-size: contain;
	background-repeat: no-repeat;
}

.footer-logo .footer-logo-div {
	background: url(/static/media/solar-logo-footer.2fbe155f.svg);
}

.navbar-icon {
	margin-right: 0.5rem;
}

.nav-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	text-align: center;
}

.nav-item {
	height: 80px;
	border-bottom: 2px solid transparent;
}

.nav-item:hover {
	border-bottom: 2px solid #ff6b00;
}

.nav-links {
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	outline: none;
}

.fa-bars {
	color: #fff;
}

.menu-icon {
	display: none;
}

.visibility-hidden {
	visibility: hidden;
}

.navbar-user-card-conatiner {
	z-index: 300;
	position: fixed;
	right: -10%;
	top: 100px;
	padding: 0.4% !important;
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.489) 0%,
		rgba(204, 136, 11, 0.441) 100%
			/* rgba(17, 68, 25, 0.686) 0%, rgba(17, 102, 28, 0.604) 100% */
	);
}

.navbar-user-card-conatiner .profile-details {
	grid-template-columns: 1fr;
}

.navbar-user-card-conatiner .flex-display {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	z-index: 5;
}

.navbar-user-card-conatiner .profile-img {
	width: 16%;
	height: 100%;
}

.navbar-user-card-conatiner h4 {
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.navbar-user-card-conatiner .user-card-container {
	padding: 1%;
}

.nav-avatar-container {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.nav-avatar-container h3 {
	margin-left: 1rem;
}

.mobile-avatar-container {
	width: 70%;
	display: flex;
	flex-direction: row;
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.navbar-logo-div {
		width: 13rem;
		height: 3rem;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		justify-content: start;
		width: 100%;
		height: 90vh;
		position: absolute;
		/* top: 80px; */
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		padding-top: 12vh !important;
	}

	.nav-menu.active {
		background: #1f253bea;
		padding-top: 0rem;
		transition: all 0.6s ease;
		z-index: 1;
		left: 0;
		opacity: 1;
		width: 100vw;
		max-width: 100%;
		height: calc(var(--vh, 1vh) * 100);
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		color: #ff6b00;
		transform: scale(1.2);
		transition: all 0.3s ease;
	}

	.nav-item:hover {
		border: none;
	}

	.nav-item {
		width: 100%;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px;
		margin-left: 30px;
		/* margin-top: 2%; */
		z-index: 3;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 12px;
		right: 12px;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		z-index: 3;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}

	.nav-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 80px;
	}
}

.flag-select__options {
	background: #ffffffb8 !important;
}

.flag-select__option:not(.flag-select__option--placeholder):hover,
.flag-select__option:not(.flag-select__option--placeholder):active {
	background: #eaeaeacb !important;
}

.flag-select__btn {
	color: white !important;
}

.flag-select__btn[aria-expanded="true"]:after {
	border-bottom: 5px solid #ffffff !important;
	border-top: 0 !important;
}

.flag-select__btn:after {
	border-top: 5px solid #ffffff !important;
	border-bottom: 0 !important;
}

.flag-select__options {
	right: 0 !important;
}

.language-select-div {
	width: 5vw;
	display: flex;
	margin-top: 7px;
	margin-left: 20px;
}

.flag-select__option__label {
	font-size: 1.2em !important;
}

@media screen and (max-width: 860px) {
	.language-select-div {
		width: 20vw;
		display: block;
		margin: auto;
	}
}

.floating-menu {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	color: white;
	width: 25%;
	z-index: 999;
	align-items: center;
	justify-content: center;
	padding-left: 15%;
}
.no-display {
	display: none;
}

.floating-menu h6 {
	margin: 20px 15px;
	padding: 2px 0px;
	font-weight: 400 !important;
	letter-spacing: 0.3rem;
	white-space: nowrap;
	font-size: 1rem;
	cursor: pointer;
	transition: "transform 0.3s ease-in-out";
}

.floating-menu h6:hover {
	color: #f37021;
}
.special-border {
	border-left: 1px solid white;
	border-right: 1px solid white;
}

h6:nth-of-type(2) {
	border-left: 1px solid white;
	border-right: 1px solid white;
}
.floating-menu .active {
	border-bottom: solid 3px #ff7119;
	color: #f37021;
}

@media (max-width: 1200px) {
	.floating-menu {
		padding-left: 4%;
	}
}

@media (max-width: 960px) {
	.floating-menu {
		grid-template-columns: 1fr;
	}
}

@font-face {
	font-family: Antipasto;
	src: url(/static/media/antipasto.demibold.48632ef4.ttf);
}
body {
	background: rgba(78, 78, 78, 0.022);
}
.hero-welcome {
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	background: url(/static/media/HeroBackground.73596915.jpeg) center no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	position: relative;
}
a {
	text-decoration: none;
}

.hero-container {
	margin-left: 7vw;
	margin-top: -5vh;
	z-index: 2;
}

.right-hero-shadow {
	width: 60vw;
	height: calc(var(--vh, 1vh) * 100);
	background: transparent
		linear-gradient(270deg, #00000000 0%, #000000c4 33%, #000000 90%) 0% 0%
		no-repeat padding-box;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}

.bottom-hero-shadow {
	width: 100vw;
	max-width: 100%;
	height: 30vh;
	background: transparent
		linear-gradient(180deg, #00000000 0%, #000000f0 73%, #000000 100%) 0% 0%
		no-repeat padding-box;
	bottom: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}
.upper-hero-shadow {
	width: 100vw;
	max-width: 100%;
	height: 18vh;
	background: transparent
		linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%) 0% 0%
		no-repeat padding-box;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}

.hero-sub-headline {
	opacity: 0;
	font-size: 6.3rem;
	font-family: "Poppins", cursive;
	color: #f8802bc0;
	font-weight: 200;
	line-height: 0.4;
	letter-spacing: 2px;
	animation: fadeUp 0.5s forwards;
	animation-delay: 1s;
}
/*Make first letter twice bigger than the rest of the text*/
.hero-first-letter {
	font-family: "Poppins", cursive;
	text-transform: uppercase;
	font-size: 9.3rem;
	font-weight: 400;
}

.hero-headline {
	color: #fff;
	font-size: 2rem;
	font-family: "Poppins", sans-serif;
	letter-spacing: 0.5rem;
	/*   margin-right: -0.5rem;
 */
	animation: fadeUp 0.5s forwards;
	/* animation-delay: 1s; */
	margin: 0 -0.5rem 0 0;
	font-weight: 500;
	width: 40%;
	text-align: left;
	font: normal normal normal 32px/40px Poppins;
	letter-spacing: 2px;
	color: #ffffff;
	text-shadow: 0px 3px 13px #00000040;
}

.hero-bellow-text {
	color: #fff;
	font-size: 2rem;
	font-family: "Roboto", sans-serif;
	letter-spacing: 0.3rem;
	/*   margin-right: -0.5rem;
 */
	animation: scale 0.5s forwards;
	margin: 2vh -0.5rem 0 0;
	margin-bottom: 4vh;
}
.top-line {
	color: #fff;
	font-size: 1.3rem;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 7.4px;
	text-transform: uppercase;
	margin-bottom: 3rem;
}
.top-line span {
	color: #ff6b00;
}

.hero-separator {
	display: flex;
	align-items: center;
	justify-content: center;
}
.hero-line {
	width: 100%;
	max-width: 8.4rem;
	height: 0.25rem;
	background-color: #fff;
	position: relative;
	opacity: 0;
	animation: grow 2s forwards;
	animation-delay: 2s;
}

.slide-in-bottom {
	animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation-delay: 0.7s;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@keyframes slide-in-bottom {
	0% {
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 1;
	}
}

.hero-line-right::before,
.hero-line-left::before {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border: 0.5rem solid transparent;
	opacity: 0;
	animation: grow 0.5s forwards;
	animation-delay: 1.2s;
}
.hero-line-right::before {
	border-right-color: #fff;
	right: 0;
}
.hero-line-left::before {
	border-left-color: #fff;
	left: 0;
}
.hero-asterisk {
	font-size: 3.2rem;
	color: #fa7312;
	margin: 0 1.6rem;
	opacity: 0;
	animation: spin 0.5s forwards;
	animation-delay: 0.7s;
}
.hero-single-animation {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	opacity: 0;
	animation: fadeDown 0.5s forwards;
	animation-delay: 1.5s;
	display: flex;
	flex-direction: column;
}

.hero-single-animation a {
	margin-bottom: 20px;
}

.hero-headline {
	margin-bottom: 4vh;
}
.hero-headline-description h5 {
	color: #fff;
	font-size: 1.4rem;
	font-weight: 100;
	text-transform: uppercase;
	margin-bottom: 1.2rem;
	letter-spacing: 3px;
	margin-right: -3px;
}
.btn {
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-right: -2px;
}
.cta-btn {
	font-size: 1.1rem;
	background-color: #fff;
	padding: 0.9rem 1.8rem;
	color: var(--body-font-color);
	border-radius: 0.4rem;
	transition: background-color 0.5s;
}
.cta-btn:hover,
.cta-btn:focus,
.cta-btn:active {
	color: #fff;
	background-color: var(--fav-font-color);
}

.find-roof-button {
	font-size: 1.7rem;
}

.perks-container {
	height: 15vh;
	position: absolute;
	bottom: 0;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 90vw;
	align-self: center;
}

.perks-container :last-child {
	border: none;
}

@media screen and (max-width: 991px) {
	.container {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media screen and (max-width: 768px) {
	.home__hero-text-wrapper {
		padding-bottom: 65px;
	}

	.col {
		max-width: 100%;
		flex-basis: 100%;
	}
	.find-roof-button {
		font-size: 1.1rem;
	}
}

@media only screen and (max-width: 768px) {
	html,
	body {
		overflow-x: hidden;
	}
	.hero-sub-headline {
		font-size: 3rem;
	}
	.hero-bellow-text {
		font-size: 1.5rem;
	}
	.hero-first-letter {
		font-size: 3.8rem;
	}
	.hero-headline {
		font-size: 2.5rem;
		margin-top: 0.4rem;
		width: 100%;
		text-align: center;
	}
	.hero-line {
		max-width: 5.5rem;
		height: 0.15rem;
	}
	.hero-asterisk {
		font-size: 1rem;
	}
	.hero-headline-description {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hero-headline-description h5 {
		font-size: 1rem;
	}
	.hero-container .btn {
		width: 100%;
	}
	.hero-container {
		width: 80%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.perks-container {
		/* display: none; */
	}
	.perk-item h3 {
		font-size: 0.8rem;
	}
	.fa-lg {
		font-size: 1.6em !important;
	}
}

@media only screen and (max-width: 550px) {
	.perks-container {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.hero-headline {
		font-size: 1.6rem;
	}
	.map-with-controls-header h3 {
		font-size: 1.1rem;
	}
}

/*Animations*/
@keyframes fadeUp {
	0% {
		transform: translateY(4rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0s);
	}
}

@keyframes scale {
	0% {
		transform: scale(2);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes grow {
	0% {
		width: 0;
	}
	100% {
		opacity: 1;
		width: 100%;
	}
}

@keyframes fadeDown {
	0% {
		transform: translateY(-1rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0s);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		opacity: 1;
		transform: rotate(-360deg);
	}
}
@keyframes up {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(-1rem);
	}
}

.perk-item h3 {
	text-align: left;
	letter-spacing: 0px;
	margin-left: 1rem;
	font-weight: 300;
	font-family: "Poppins";
	width: 65%;
	letter-spacing: 1px;
}

.perk-item {
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	padding: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffffdc;
}

.perk-grid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.fa-lg {
	font-size: 2em !important;
}

.m-btn {
	margin: 10px;
	padding: 15px 20px;
}
.control-buttons-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 90%;
	margin: 0 auto;
	margin-top: 5%;
	margin-bottom: 3%;
}

@media screen and (max-width: 1100px) {
	.control-buttons-container {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 768px) {
	.control-buttons-container {
		grid-template-columns: 1fr 1fr;
	}
}

.control-icon {
	width: 10%;
	height: 100%;
}

.MuiButton-containedSizeLarge {
	padding: 8px 40px !important;
}

@font-face {
    font-family: "flaticon";
    src: url(/static/media/flaticon.0f3793e6.ttf) format("truetype"),
url(/static/media/flaticon.94139d24.woff) format("woff"),
url(/static/media/flaticon.60d3696b.woff2) format("woff2"),
url(/static/media/flaticon.1c1538ee.eot#iefix) format("embedded-opentype"),
url(/static/media/flaticon.cc1538eb.svg#flaticon) format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-3d-printer:before {
    content: "\f101";
}
.flaticon-ruler:before {
    content: "\f102";
}
.flaticon-graphic-design:before {
    content: "\f103";
}
.flaticon-measure:before {
    content: "\f104";
}
.flaticon-rubber:before {
    content: "\f105";
}
.flaticon-research:before {
    content: "\f106";
}
.flaticon-accounting:before {
    content: "\f107";
}
.flaticon-pin:before {
    content: "\f108";
}
.flaticon-phone-call:before {
    content: "\f109";
}
.flaticon-email:before {
    content: "\f10a";
}
.flaticon-pencil:before {
    content: "\f10b";
}
.flaticon-tools:before {
    content: "\f10c";
}
.flaticon-layers:before {
    content: "\f10d";
}
.flaticon-sun:before {
    content: "\f10e";
}
.flaticon-sun-1:before {
    content: "\f10f";
}
.flaticon-investor:before {
    content: "\f110";
}
.flaticon-settings:before {
    content: "\f111";
}
.flaticon-save-money:before {
    content: "\f112";
}
.flaticon-design:before {
    content: "\f113";
}
.flaticon-scale:before {
    content: "\f114";
}
.flaticon-project:before {
    content: "\f115";
}
.flaticon-close:before {
    content: "\f116";
}
.flaticon-magnifying-glass-search:before {
    content: "\f117";
}
.flaticon-gear:before {
    content: "\f118";
}
.flaticon-trash:before {
    content: "\f119";
}
.flaticon-check:before {
    content: "\f11a";
}
.flaticon-check-1:before {
    content: "\f11b";
}
.flaticon-upload-big-arrow:before {
    content: "\f11c";
}

.output-fields {
	width: 100%;
	display: grid;
	grid-template-columns: 5fr 1.5fr;
	align-content: center;
	margin: 2% auto;
}
.output-field {
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.4rem 0 0 0.4rem;
	text-align: right;
	font-size: 1.5rem;
	padding: 3%;
	padding-right: 5%;
	border-right: none;
}

.input-field-important {
	text-align: left;
	border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
	border: 1px solid #ced4da;
}

.unit {
	border: 1px solid #ced4da;
	border-radius: 0 0.4rem 0.4rem 0;
	font-size: 1.5rem;
	padding: 3% 30%;
	background-color: #ffc107c9;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	color: white;
}

.otput-label {
	display: block;
	font-size: 1.2rem;
	font-weight: 300;
}

.output-and-label {
	margin-top: 3%;
	width: 100%;
	padding-bottom: 0;
}

@media screen and (max-width: 1200px) {
	.map-with-controls-container .output-field {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 1000px) {
	.map-with-controls-container .output-field {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 900px) {
	.map-with-controls-container .output-field {
		font-size: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.map-with-controls-container .output-field {
		font-size: 1.3rem;
	}
}

.slider-and-image-container {
	display: grid;
	grid-template-columns: 1fr 5fr;
	box-shadow: 2px 3px 3px #00000029;
	border: 1px solid #70707033;
	/* margin-right: 5%; */
	border-radius: 10px 10px 10px 10px;
	margin-top: 3%;
}

.slider-and-image-container p {
	text-align: center;
	font-weight: 700;
	color: white;
	margin-top: -10%;
	margin-left: 6%;
}

.roof-img-with-slope {
	background-color: #ff6b00;
	border-radius: 10px 0px 0px 10px;
	margin: -1px;
}

.MuiSlider-root {
	color: #ff6b00 !important;
}
.MuiSlider-marked {
	margin-bottom: 0 !important;
}

@media only screen and (max-width: 1000px) {
	.MuiSlider-markLabel {
		font-size: 1.2rem !important;
	}
}

@media only screen and (max-width: 500px) {
	.MuiSlider-markLabel {
		font-size: 0.9rem !important;
	}
	.MuiSlider-markLabel {
		top: 25px !important;
	}
}

.estimation-results {
	padding: 2%;
	padding-top: 0;
}

[title~="Stop"] {
	display: none !important;
}
/* 
[aria-label~="Stop drawing"] {
	display: none !important;
} */

.estimation-results-border {
	/* border: 2px solid rgba(255, 137, 58, 0.646);
	border-radius: 0 0px 20px 0;
	border-top: 0; */
	margin-top: 4%;
}
.measure-tool-svg-overlay .segment-text .segment-measure-text:first-child {
	-webkit-user-select: none;
	        user-select: none;
}
.bill-unit {
	background-color: #ffce3b;
	/* margin: -1px 0; */
	padding: 3% 0;
}

/* .gmnoprint {
	display: none;
} */

.output-field-bill {
	width: 90% !important;
}

.bill-output-label {
	display: none;
}

/* Chrome,
Safari,
Edge,
Opera input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

Firefox
input[type="number"] {
	-moz-appearance: textfield;
}
*/
.map-with-controls-container {
	display: grid;
	grid-template-columns: 2fr 5fr;
	padding-bottom: 0;
	margin-bottom: 0px;
	/* padding-top: 5%; */
}

.map-with-controls-container-simplified {
	grid-template-columns: 1fr;
}

.step-container-main {
	display: grid;
	grid-template-columns: 2fr 5fr;
}

.step-explenation {
	display: flex;
	align-items: center;
	padding: 1% 4%;
}

.step-explenation h3 {
	font: normal normal 300 18px/20px Poppins;
	font-size: 1.3rem;
}

.map-with-controls-header {
	text-align: center;
	padding: 6vh 20vw;
	position: relative;
}

.map-with-controls-header h1 {
	font-size: 3rem;
	font-weight: 700;
}

.map-with-controls-header h3 {
	font-size: 1.4rem;
	font-weight: 300;
	letter-spacing: 0.05rem;
}

.orangeletters {
	color: #ff6b00;
	font-weight: 700;
}

.additional-info {
	justify-self: right;
	font-size: 1.3rem;
	cursor: pointer;
	padding-top: 2%;
}

.step-grid {
	display: grid;
	grid-template-columns: 5fr 1fr;
}

.add-project-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.please-log-in-h3 {
	padding: 0.5%;
	color: #2e2e2e;
}

.please-log-in-h3 a {
	color: #ff6b00;
	cursor: pointer;
	text-align: center;
}

.controls-container {
	/* padding-left: 5%; */
	/* border: 2px solid rgba(255, 137, 58, 0.646); */
	border-radius: 0 20px 20px 0;
	margin-left: -2px;
}

.controls-container .upper-border {
	width: 100%;
	height: 40px;
	background: transparent linear-gradient(180deg, #000000 0%, #ffffff 100%) 0%
		0% no-repeat padding-box;

	mix-blend-mode: multiply;
	opacity: 0.07;
	position: absolute;
}

.controls-container .upper-border-small {
	width: 100%;
	height: 5px;
	background: transparent linear-gradient(180deg, #000000 0%, #ffffff 100%) 0%
		0% no-repeat padding-box;
	mix-blend-mode: multiply;
	opacity: 0.04;
	position: absolute;
}

.controls-container .controls-container-button {
	margin-top: 2%;
}

.controls-container h2 {
	padding-top: 2%;
	padding-bottom: 4%;
}

/* .triangle-bottom {
	position: absolute;
	margin-left: -18.5px;
	bottom: -19px;
	z-index: 99;
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 20px 0 20px;
	border-color: #ffffff transparent transparent transparent;
	line-height: 0;
	_border-color: #ffffff #000000 #000000 #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
} */

.estimate-profit {
	display: flex;
	align-content: center;
	justify-content: center;
	width: 96%;
	margin: 0 auto;
	margin-top: 3%;
}
.gm-style {
	font: 400 14px "Poppins", Arial, sans-serif !important;
}

.measure-tool-svg-overlay .base .base-line:first-child {
	stroke: #ff0707 !important;
}

/* .measure-tool-svg-overlay .segment-text .segment-measure-text {
	text-shadow: 2px 2px 2px #ffffff, 0px 0px 3px #ffffff !important;
} */

.measure-tool-svg-overlay .segment-text .segment-measure-text:first-child {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-circle .head-circle:first-child {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-circle :nth-child(2) {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-measure-text {
	display: none;
}
.step-container {
	padding: 3% 0;
	padding-left: 5%;
	padding-right: 5%;
}

.step-container h2 {
	text-align: left;
	font: "Poppins";
	letter-spacing: 0px;
	color: #000000;
	font-weight: 700;
	padding-bottom: 1%;
	font-size: 1.2rem;
}

.step-container h3 {
	font: normal normal 300 18px/20px Poppins;
	font-size: 1.3rem;
}

.roofko-score {
	display: flex;
	flex-direction: row;
	margin-bottom: 3% !important;
	padding-left: 12% !important;
	padding-right: 12% !important;
}

.roofko-score-right {
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.roofko-score-left {
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align: left;
}

.roofko-score-percent-span {
	font-size: 3rem;
	color: rgb(255, 107, 0);
}

.roofko-score h2 {
	font-size: 2.5rem;
}
.roofko-score h3 {
	font-size: 1.5rem;
	width: 70%;
}

@media only screen and (max-width: 768px) {
	.map-with-controls-container {
		grid-template-columns: 1fr;
	}

	.map-container-width {
		height: 70vh !important;
	}
	.controls-container {
		border-radius: 0 0px 20px 20px;
	}
	.roofko-score {
		flex-direction: column;
	}
	.roofko-score-left {
		width: 100%;
	}
	.roofko-score h2 {
		text-align: center;
	}
	.roofko-score h3 {
		width: 100%;
		font-size: 1.1rem;
		text-align: justify;
	}
	.roofko-score-right {
		width: 100%;
	}

	.map-with-controls-header {
		text-align: center;
		padding: 8vh 8vw;
	}
	.map-with-controls-header h3 {
		font-size: 1.5rem;
	}
	.please-log-in-h3 {
		font-size: 1rem;
		text-align: center;
		margin: 0px 10px;
	}
	.step-explenation {
		display: none;
	}
	.step-container-main {
		grid-template-columns: 1fr;
	}
	.calculation-button h2 {
		font-size: 1.3em;
	}
}

@media only screen and (max-width: 394px) {
	.calculation-button h2 {
		font-size: 1.35em;
	}
}

@media only screen and (max-width: 276px) {
	.bill-unit {
		font-size: 1.3rem !important;
	}
	.unit {
		font-size: 1.3rem !important;
	}
}
/* 
@media only screen and (max-width: 480px) {
	.touch-circle {
		display: none;
	}
} */

.project-data-grid {
	display: grid;
	grid-template-columns: 5fr 5fr;
	grid-gap: 2%;
}

.project-data-container {
	margin: 0 auto;
}

.project-data-margin-top {
	margin-top: -5vh;
}

.project-data-grid h3 {
	font-weight: 500;
	font-size: 1.4rem;
	text-align: center;
	margin: 2%;
	letter-spacing: 0.1rem;
	margin: 4% 0;
	padding: 4% 0;
}

@media only screen and (max-width: 1000px) {
	.project-data-margin-top {
		margin-top: 0;
	}
}

@media only screen and (max-width: 768px) {
	.project-data-grid {
		grid-template-columns: 1fr;
	}
	.output-data-row .output-label {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 500px) {
	.project-data-grid {
		grid-template-columns: 1fr;
	}

	.output-data-row .output-label {
		font-size: 0.8rem !important;
	}
	.cashflow-project-data {
		display: none;
	}
}

.output-data-row .output-fields {
	width: 80%;
	display: grid;
	grid-template-columns: 5fr 2fr;
	align-content: center;
	margin: 2% auto;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
.output-data-row .output-field {
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.4rem 0 0 0.4rem;
	text-align: right;
	font-size: 1.5rem;
	padding: 2%;
	padding-right: 5%;
	border-right: none;
}

.output-data-row .unit {
	border: 1px solid #ced4da;
	border-radius: 0 0.4rem 0.4rem 0;
	font-size: 1.5rem;
	padding: 3% 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	text-align: center;
}

.output-data-row .output-label {
	display: block;
	font-size: 1.2rem;
	text-align: left;
	text-transform: uppercase;
	font-weight: 400;
	margin-right: 1rem;
	color: black;
}

.output-data-row .output-and-label {
	margin-top: 1%;
	width: 100%;
	padding: 0 1%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.nogrid {
	grid-template-columns: 1fr !important;
}

@media only screen and (max-width: 1000px) {
	.output-data-row .output-label {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 500px) {
	.output-data-row .output-field {
		font-size: 1rem;
		padding-right: 10%;
	}

	.output-data-row .unit {
		font-size: 1rem;
		padding: 3% 2%;
	}
}

.bar-chart-container {
	width: 80%;
	margin: 0 auto;
	padding-top: 2%;
}

@media only screen and (max-width: 1000px) {
	.bar-chart-container {
		width: 75%;
	}
}

.MuiPaper-elevation1 {
	box-shadow: none !important;
}

.MuiExpansionPanelSummary-root {
	padding: 0 1% !important;
}

.expansion-panels-container h4 {
	font-weight: 500;
	color: #5e5e5e;
}

.MuiExpansionPanelDetails-root {
	padding: 0 !important;
}
.MuiAccordionDetails-root {
	display: block !important;
	padding: 0 !important;
}
.MuiExpansionPanelDetails-root {
	display: block !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
	min-height: 30px !important;
}

.circle {
	overflow: hidden;
	position: relative;
	background-color: #e3e3e3;
}
.left-wrap {
	overflow: hidden;
	position: absolute;
	top: 0;
}
.right-wrap {
	overflow: hidden;
	position: absolute;
	top: 0;
}

.loader {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1000;
	transform-origin: 0 50%;
}
.loader2 {
	position: absolute;
	left: 0;
	top: 0;
	border-radius: 1000;
	transform-origin: 100% 50%;
}

.inner-circle {
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.circle .text {
	font-size: 11;
	color: #888;
}

.percent {
	font-size: 17px;
}

.MuiDialog-paperWidthSm {
	max-width: 720px !important;
}

.MuiCardMedia-root {
	background-size: contain !important;
}

@media screen and (max-width: 500px) {
	.explanation {
		grid-template-columns: 1fr;
	}
}

.explenation-dialog-z-index {
	z-index: 100000000000000000;
}

.MuiTypography-body1 {
	font-weight: 600 !important;
}

.measure-explenation .MuiTypography-h6 {
	font-size: 1.8rem !important;
	text-align: center;
}

.alert_dialog_measure_explenation_title {
	text-align: center !important;
}

@media only screen and (max-width: 768px) {
	.explenation-dialog-z-index .MuiDialog-paper {
		margin: 6px !important;
	}
	.alert_dialog_measure_explenation_title {
		text-align: center !important;
		margin-bottom: 20px;
	}
	.MuiDialogContent-root {
		padding: 8px 10px !important;
	}
}

.react-flag-select {
	margin-top: 3%;
}
.flag-select__btn {
	cursor: pointer;
}

.form-wrapper {
	background: linear-gradient(rgba(87, 87, 87, 0.564), rgba(0, 0, 0, 0.386)),
		url(/static/media/HeroBackground.73596915.jpeg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 80px;
	height: calc(var(--vh, 1vh) * 100);
	box-sizing: border-box;
	padding-top: 13vh;
}
.register-background {
	background: linear-gradient(rgba(87, 87, 87, 0.564), rgba(0, 0, 0, 0.386)),
		url(/static/media/HeroBackground.73596915.jpeg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: calc(var(--vh, 1vh) * 100);
	padding: 80px;
	padding-top: 135px;
	box-sizing: border-box;
	padding-top: 13vh;
}

.form-login-bck {
	background: url(/static/media/HeroBackground.73596915.jpeg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 80px;
}

.form-container {
	margin: 0 auto;
	width: 65vw;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
	position: relative;
	opacity: 0.85;
	border-radius: 10px;
	height: 600px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	background: rgba(0, 0, 0, 0.089);
	-webkit-backdrop-filter: blur(2px);
	        backdrop-filter: blur(2px);
}

.close-btn {
	position: absolute;
	top: 2%;
	right: 3%;
	font-size: 1.5rem;
	z-index: 1;
	color: #fff;
	cursor: pointer;
}

.form-content-left {
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.489) 0%,
		rgba(204, 136, 11, 0.441) 100%
			/*     rgba(17, 68, 25, 0.686) 0%,
    rgba(17, 102, 28, 0.604) 100% */
	);
	border-radius: 10px 0 0 10px;
	position: relative;
}

.form-img {
	width: 80%;
	height: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-img-2 {
	width: 60%;
	height: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-success {
	text-align: center;
	font-size: 24px;
	margin-top: 80px;
	color: #fff;
}

.form-content-right {
	border-radius: 0 10px 10px 0;
	position: relative;
	background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
	overflow: auto;
}

.form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Arial, Helvetica, sans-serif;
}

.form h1 {
	font-size: 3.2rem;
	text-align: center;
	width: 80%;
	color: #fff;
	font-family: "Poppins", cursive;
	margin-bottom: 1.5rem;
}
.form .joinTeamh1 {
	width: 100%;
}
.form .form-storyP {
	font-size: 1.9rem;
	color: #fff;
	margin-bottom: 1rem;
	font-family: "Poppins", cursive;
	text-align: center;
}
.form-svg {
	width: 170px;
	height: 170px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.traveller {
	background-image: url(/static/media/investor.235a5c0a.svg);
}
.host {
	background-image: url(/static/media/electrical-engineer.808bb157.svg);
}
.form-inputs {
	margin-bottom: 0.8rem;
	width: 80%;
	overflow: hidden;
}

.form-inputs p {
	font-size: 0.8rem;
	margin-top: 0.5rem;
	color: #f00e0e;
}

.form-label {
	display: inline-block;
	font-size: 1rem;
	margin-bottom: 6px;
	color: #fff;
}

.form-input {
	display: block;
	/* padding-left: 10px; */
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 100%;
	border: none;
}

.form-input::placeholder {
	color: #595959;
	font-size: 12px;
}

.form-input-login {
	font-size: 0.9rem;
	margin-top: 2rem;
	color: #fff;
	width: 80%;
	text-align: center;
}

.form-input-login a {
	text-decoration: none;
	color: #ff6b00;
	font-weight: 600;
}

#test5 {
	background-color: #ff6b00;
	margin-left: 2rem;
	margin-top: 0.1rem;
}

/*FORM TOGGLE*/
.hideForm {
	display: none;
}

.additional-question {
	color: white;
	font-family: "Poppins", Helvetica, sans-serif;
	letter-spacing: 0.05rem;
}

.link-span {
	color: #ff6b00;
	cursor: pointer;
}

/*TOGGLE BUTTON*/

input[type="checkbox"] {
	display: none;
}

#button {
	position: absolute;
	top: 35%;
	right: 70%;
	display: block;
	width: 291px;
	height: 125px;
	background-color: #000;
	border-radius: 350px;
	cursor: pointer;
	transform: scale(0.4);
	margin: 50px auto;
}

.privacy-and-terms {
	margin-top: 0rem;
	margin-bottom: 0.3rem;
	width: 90%;
}

#knob {
	width: 112px;
	height: 104px;
	background-image: url(/static/media/HeroBackground.73596915.jpeg);
	background-size: 701px;
	position: relative;
	top: 9px;
	left: 14px;
	border-radius: 290px;
	transition: 0.4s ease left, 0.4s ease background-position;
	z-index: 2;
}

#subscribe,
#alright {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 36px;
	font-weight: bold;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	margin-left: 171px;
	z-index: 1;
}

#alright {
	margin-left: 30px;
}

#lol-checkbox:checked + #button #knob {
	left: 170px;
	background-position: -350px 0;
}

.file-upload-div {
	margin: 10px;
	margin-left: 0;
	color: white;
	margin-top: 5px;
}
@media screen and (max-width: 768px) {
	.form-content-left {
		display: none;
	}
	.form-container {
		margin: 0 auto;
		width: 90vw;
		box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%);
		position: relative;
		opacity: 0.85;
		border-radius: 10px;
		height: 80vh;
		display: grid;
		grid-template-columns: 1fr;
		background: rgba(0, 0, 0, 0.089);
	}
	.form-wrapper {
		padding: 150px 0 30px;
	}
}

.form-container-make-an-offer-form {
	grid-template-columns: 1fr;
	width: 35vw;
}

.only-for-make-an-offer {
	grid-template-columns: 1fr;
	width: 60vw;
	height: 650px;
}

.form-container-make-an-offer-form h1 {
	font-size: 1.6rem;
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 1rem;
}

.form-container-make-an-offer-form .checkboxes {
	align-self: flex-start;
	margin: 1% auto;
	width: 90%;
	color: white;
}

.form-container-make-an-offer-form textarea {
	padding-top: 10px;
	height: 130px;
}

.form-container-make-an-offer-form h2 {
	width: 80%;
	color: white;
	margin-top: 2%;
}

.form-container-make-an-offer-form .form-inputs {
	margin: 1% auto;
	width: 90%;
}

.form-container-make-an-offer-form .make-an-offer-button {
	margin: 2%;
}

.form-container-make-an-offer-form fieldset {
	margin: 3%;
	border-radius: 2%;
}

.form-container-make-an-offer-form legend {
	padding: 2%;
	color: white;
}

.close-button {
	color: white;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 5%;
	cursor: pointer;
}

.only-for-make-an-offer .close-button {
	margin-top: 3%;
}

.form-inputs-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.last-form-input {
	margin-bottom: 20px !important;
}

@media only screen and (max-width: 768px) {
	.form-container-make-an-offer-form {
		width: 80vw;
	}
}

@media only screen and (max-width: 500px) {
	.form-container-make-an-offer-form {
		width: 90vw;
	}

	.ReactModal__Content {
		border-radius: 0px !important;
	}

	.form-content-right {
		border-radius: 0px !important;
	}

	.form-inputs-grid {
		grid-template-columns: 1fr;
	}
}

.choose-calculation-type-section {
}

.calculation-type-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3% 10%;
}

.choose-calculation {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	grid-auto-rows: 1fr;
	grid-auto-flow: column;
	box-sizing: border-box;
	overflow: hidden;
}

.chosen {
	background-color: #e8e8e8;
}

.notchosen {
	background-color: white;
}

.calculation-button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	padding: 10px 0;
	transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-webkit-transition: 0.5s ease;
	height: 100%;
}

.calculation-button:active {
	color: #ff6b00;
	box-shadow: 0 0 10px 10px rgba(116, 116, 116, 0.6);
}

.additional-calculation-explenation {
	padding: 4%;
	padding-bottom: 2%;
	text-align: center;
	font-weight: 300;
	letter-spacing: 0.03rem;
	font-size: 110%;
}

.calculation-button h2 {
	text-align: center;
}

.calculation-button-right {
	-webkit-clip-path: polygon(
		10% 0,
		100% 0,
		100% 0,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 32%
	);
	clip-path: polygon(
		10% 0,
		100% 0,
		100% 0,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 32%
	);
}

.calculation-button-left {
	-webkit-clip-path: polygon(
		0 0,
		90% 0,
		100% 32%,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 0
	);
	clip-path: polygon(
		0 0,
		90% 0,
		100% 32%,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 0
	);
}

@media only screen and (max-width: 500px) {
	.calculation-button p {
		display: none;
	}
}

.home-page-container {
	width: 100%;
	margin: 0 auto;
	/* background-image: url("./../Assets/Images/wallpaperbetter.jpg"); */
	background-size: contain;
}

.subscribe-section {
	width: 100%;
	/* height: 50vh; */
	background: linear-gradient(
			180deg,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 106, 0, 0.9) 100%
		),
		url(/static/media/SubscribeBackground.b4b3875a.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.subscribe-section-inner {
	width: 25%;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	background: #00000020 0% 0% no-repeat padding-box;
	padding: 3% 3%;
	margin: 3% auto;
	border-radius: 7px;
	padding-top: 3%;
}

#submit-subheading {
	font-size: 1.2em;
	font-weight: lighter;
}

#subscribe-email {
	width: 100% !important;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	position: relative;
	z-index: 1000;
	margin: 10px 0;
}

#ask-question {
	width: 97% !important;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	top: 6px;
	z-index: 1000;
	padding-left: 10px !important;
	padding-top: 20px !important;
	text-align: center !important;
}

.submit-question {
	margin-top: 10px !important;
}

.form-inputs-subscribe {
	width: 100% !important;
}

.button-subscribe {
	width: 100%;
	padding: 10px 26px;
	font-size: 15px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	padding-top: 12px;
}

.form-input-subscribe {
	padding-left: 0 !important;
	height: 50px;
	box-shadow: 0px 3px 6px #00000029;
}

input {
	text-align: center;
}

.submit-heading {
	text-align: center;
	color: white;
	text-shadow: 0px 3px 7px #00000029;
	font-size: 1.5em;
	margin-bottom: 6%;
}

.fun-facts-heading {
	font-size: 3rem;
	font-weight: 700;
	color: white;
	margin-top: 4%;
}

@media only screen and (max-width: 1200px) {
	.subscribe-section-inner {
		width: 33%;
	}
}

@media only screen and (max-width: 750px) {
	.subscribe-section-inner {
		width: 50%;
	}
}
@media only screen and (max-width: 500px) {
	.subscribe-section-inner {
		width: 80%;
	}
}

@media only screen and (max-width: 400px) {
	.subscribe-section-inner {
		width: 85%;
	}
}

.container-fun-facts {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 100%;
	margin: 20px 0 10px;
}
.container-fun-facts .card {
	cursor: pointer;
	width: 300px;
	margin: 10px;
	background-color: black;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
	border-radius: 20px;
}
.container-fun-facts .card:hover .card__caption {
	top: 50%;
	transform: translateY(-50%);
}
.container-fun-facts .card:hover .card__image {
	transform: translateY(-10px);
}
.container-fun-facts .card:hover .card__thumb::after {
	top: 0;
}
.container-fun-facts .card:hover .card__snippet {
	margin: 20px 0;
}
.container-fun-facts .card__thumb {
	position: relative;
	max-height: 400px;
	overflow: hidden;
	border-radius: 20px;
}

.container-fun-facts .card__thumb img {
	opacity: 0.9;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__thumb {
		max-height: 350px;
	}
}
.container-fun-facts .card__thumb::after {
	position: absolute;
	top: 0;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.5) 40%,
		rgba(255, 255, 255, 0) 100%
	);
	transition: 0.3s;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__thumb::after {
		top: calc(100% - 140px);
	}
}
.container-fun-facts .container-fun-facts .card__image {
	transition: 0.5s ease-in-out;
}
.container-fun-facts .card__caption {
	position: absolute;
	top: 50%;
	z-index: 1;
	padding: 0 20px;
	color: white;
	transform: translateY(-50%);
	text-align: center;
	transition: 0.3s;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__caption {
		top: calc(100% - 110px);
		transform: unset;
	}
}
.container-fun-facts .card__title {
	display: -webkit-box;
	max-height: 85px;
	overflow: hidden;
	font-family: "Poppins", serif;
	font-size: 23px;
	line-height: 28px;
	text-shadow: 0px 1px 5px black;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.container-fun-facts .card__snippet {
	display: -webkit-box;
	max-height: 150px;
	margin: 20px 0;
	overflow: hidden;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	line-height: 20px;
	text-overflow: ellipsis;
	transition: 0.5s ease-in-out;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__snippet {
		margin: 60px 0;
	}
}
.container-fun-facts .card__button {
	display: inline-block;
	padding: 10px 20px;
	color: white;
	border: 1px solid white;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: none;
	transition: 0.3s;
}
.container-fun-facts .card__button:hover {
	color: black;
	background-color: white;
}
.container-fun-facts .disclaimer {
	position: fixed;
	bottom: 0;
	left: 50%;
	z-index: 2;
	box-sizing: border-box;
	width: 100%;
	padding: 20px 10px;
	background-color: white;
	transform: translateX(-50%);
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	text-align: center;
}
.container-fun-facts .disclaimer__link {
	color: #755d87;
	text-decoration: none;
}

.suggestions {
	border: 1px solid rgb(62, 62, 62);
	padding-top: 10px;
	margin-top: -8px;
}

.city-suggestion {
	padding-top: 1.5%;
	padding-bottom: 1.5%;
}

.attribution-google {
	width: 40;
	height: auto;
	margin: 0 auto;
	float: right;
	background-color: white;
}
.suggestion-grid {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.slider-and-image-container-orientation .MuiSlider-track {
	width: 0% !important;
}

.slider-and-image-container-orientation {
	display: flex !important;
	flex-direction: column;
	border: none;
	box-shadow: none;
}

.slider-and-image-container-orientation .rotatingRoof {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-and-image-container-orientation .roof-img-with-slope {
	background-color: transparent !important;
}
#turn {
	transition: all 0.3s linear;
}

.form-success-container {
	width: 30%;
	margin: 0 auto;
	border-radius: 10px;
	position: relative;
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.75) 0%,
		rgba(204, 136, 11, 0.8) 100%
			/*     rgba(17, 68, 25, 0.686) 0%,
    rgba(17, 102, 28, 0.604) 100% */
	);
	font-family: "Poppins";
}

.form-success-container h2 {
	padding: 0 5%;
	padding-top: 0;
}

.form-success-container h4 {
	padding: 5% 3%;
	margin: 3%;
	padding-top: 0;
	font-size: 1.4rem;
}

.upper-form-success {
	padding: 8%;
	padding-top: 12%;
}

.down-form-success {
}

.form-success-container i {
	font-size: 8rem;
	margin: 5%;
	color: #ffffff;
}

.profile-container {
	margin: 0 auto;
	width: 100%;
	/* box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%); */
	border-radius: 10px;
	/* background: rgba(0, 0, 0, 0.089); */
	display: flex;
	flex-direction: column;
	padding-top: 80px;
}

.available-projects-container {
	width: 70%;
	margin: 0 auto;
	margin-top: 15vh;
}
.profile-up {
	/* height: 21rem; */
	width: 70%;
	margin: 0.5rem auto;
	/* border-bottom: 1px solid gray; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-left {
	width: 30%;
	position: relative;
	padding: 30px;
	box-sizing: border-box;
}

.available-project-overview-container {
}

/* .userTitle::before {
	position: absolute;
	left: 39%;
	transform: rotate(19deg);
	content: "User";
	color: orange;
	text-align: center;
	width: 4.6rem;
	height: 2rem;
	background: #fff;
	z-index: 1;
	font-size: 1.3rem;
} */

.hostTitle::before {
	position: absolute;
	left: 39%;
	transform: rotate(19deg);
	content: "Host";
	color: orange;
	text-align: center;
	width: 4.6rem;
	height: 2rem;
	background: #fff;
	z-index: 1;
	font-size: 1.3rem;
}
.profile-image {
	border-radius: 20%;
	width: 50%;
	height: 80%;
	box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 58%);
}
.profile-right {
	width: 40%;
	margin-left: 10%;
	padding: 30px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.profile-right .welcomeTitle {
	margin-bottom: 1rem;
}
.profile-down {
	width: 100%;
}
.profile-activitiesTitle {
	text-align: left;
}

.profile-wrapper {
	/* background: rgba(211, 211, 211, 0.384); */
	/* background-position: center; */
	/* padding: 10px; */
	/* background: url("../images/backgroundo5.svg"); */
	width: 100%;
}

.profile-details {
	margin-top: 1rem;
	/* max-width: 50%; */
}

.profile-card-details {
	grid-template-columns: 1fr;
	text-align: left;
}
.profile-name {
	margin: 1rem 0;
}

.hostOptions {
	width: 50%;
	margin: 1rem auto 0 auto;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.profile-right .detail {
	display: grid;
	grid-template-columns: 1fr 20fr;
	align-content: center;
	color: rgb(10, 10, 10);
	padding: 1.5%;
	font-size: 1.1rem;
}

.profile-right .detail .text {
	display: flex;
	align-items: center;
}

.profile-right .detail .text h4 {
	display: flex;
	align-items: center;
	padding-left: 5%;
	font-weight: 400;
}

.profile-right .detail .text .not-defined {
	font-weight: 300;
	cursor: pointer;
}
.profile-right .detail i {
	font-size: 1.4rem;
	display: flex;
}

.profile-right .detail .icon {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.MuiTypography-h4 {
	margin-top: 0.7em !important;
}

@media only screen and (max-width: 768px) {
	.profile-up {
		width: 90%;
	}
}

@media only screen and (max-width: 500px) {
	.profile-up {
		flex-direction: column;
	}

	.profile-right {
		width: 100%;
		text-align: center;
	}

	.profile-right .detail .text h4 {
		text-align: left;
	}
}

/* .badge-size {
	width: 100%;
	height: 100%;
}

.MuiAvatar-root {
	width: 100% !important;
	height: 100% !important;
}

*/

.MuiAvatar-root {
	box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
}

.MuiBadge-badge {
	height: 28% !important;
}

.MuiAvatar-circular {
	background: white;
}

.profile-up .MuiAvatar-root {
	width: 100%;
	height: 100%;
}

.profile-up .MuiBadge-root {
	width: 250px;
	height: 250px;
}

.gear-icon {
	color: rgb(0, 0, 0);
	cursor: pointer;
}

.profile-image-header {
	margin-top: 2%;
	width: 23%;
	height: 23%;
	margin-bottom: 3%;
}

.not-clickable {
	cursor: auto;
}

.profile-up .detail .icon {
	color: #ff6b00;
}
/* 
This below are changes to code after redesign at 22.10.2021,
please specify seletors with details */

.profile-wrapper .profile-up {
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;

	justify-content: flex-start;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: -12vh;
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	top: 2vh;
	margin-bottom: 2vh;
	padding-bottom: 5%;
}

.profile-wrapper .profile-container {
	display: grid;
	grid-template-columns: 2fr 5fr;
	width: 80%;
	padding-top: 0;
	align-items: start;
	min-height: 59.5vh;
}

.other-pages .profile-container {
	width: 80%;
	display: block;
}

.profile-wrapper .profile-right {
	width: 100%;
}

.profile-wrapper .profile-image-header {
	width: 50%;
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 10%;
}

.profile-wrapper .project-card-container {
	width: 100%;
}

.profile-wrapper .profile-up .MuiBadge-root {
	width: 100%;
	height: 100%;
}

.profile-wrapper .profile-down {
	margin-left: 5%;
	z-index: 2;
}

.profile-wrapper .profile-down {
	width: 95%;
}

.profile-wrapper .profile-container .description {
	width: 100%;
}

.profile-wrapper .profile-right {
	margin-left: 0;
}

.profile-wrapper .MuiAvatar-root {
	box-shadow: -1px -1px 10px 2px rgb(0 0 0 / 30%);
	-webkit-box-shadow: -1px -1px 10px 2px rgb(0 0 0 / 30%);
	-moz-box-shadow: -1px -1px 10px 2px rgba(0, 0, 0, 0.3);
}

.profile-wrapper .profile-right .detail {
	margin-left: 10%;
}

.profile-wrapper .profile-right .welcomeTitle {
	text-align: center;
}

.profile-wrapper .available-projects-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 1000px) {
	.profile-wrapper .profile-container {
		grid-template-columns: 1fr;
	}
	.profile-wrapper .profile-container .description {
		margin-top: 5%;
	}

	.profile-wrapper .profile-down {
		margin-left: 0;
		width: 100%;
	}

	.profile-wrapper .profile-up {
		position: static;
	}

	.profile-wrapper .profile-right .detail {
		font-size: 2rem;
	}
	.profile-right .detail i {
		font-size: 2.8rem;
	}
}
/* THIS IS WHERE THESE CHANGES END */

@media only screen and (max-width: 500px) {
	.profile-image-header {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.profile-wrapper .profile-right .detail {
		font-size: 1.2rem;
	}
	.profile-right .detail i {
		font-size: 2rem;
	}
	.profile-wrapper .profile-container {
		width: 90%;
	}
}

.project-card-container {
	width: 70%;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	/* height: 23vh; */
	margin: 6vh auto;
	display: grid;
	grid-template-columns: 2.1fr 3.8fr 0.1fr 0.6fr 1fr;
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	background: white;
	min-height: 220px;
}

.project-card-container img {
	border-radius: 15px;
	width: 100%;
	height: 110%;
	object-fit: cover;
	overflow: hidden;
	position: relative;
	top: -5%;
}

.main-image {
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.project-card-container .project-data {
	margin-left: 35px;
}

.project-card-container h2 {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 1px;
	margin: 10px 0px;
	margin-bottom: 20px;
	padding: 5px;
	color: #f56900;
}

.project-card-container .project-rating-container {
	width: 80%;
}

.project-card-container .location-container {
	margin: 15px -10px;
}

.project-card-container .location-container .main-image {
	width: 40%;
}

.project-card-container .location-container h4 {
	font-weight: 500;
}

.project-card-container .control-logos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.project-card-container .control-logos {
	grid-template-columns: 1fr;
}

.project-card-container .control-logos img {
	width: 23%;
	height: auto;
	border-radius: 0;
	cursor: pointer;
}

.project-card-container .status {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 500;
}

.project-card-container .project {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.project-card-container .project-data h4 {
	font-weight: 600;
	font-size: 15px;
}

.project-card-container .user-container {
	margin: auto 0;
}

.project-card-container .location-continer {
	display: grid;
	width: 70%;
	grid-template-columns: 1fr 4fr;
	margin-top: 10px;
}

.project-card-container .location-text {
	align-self: center;
}

.project-card-container .location-continer .main-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.project-card-container .contractor-data h3 {
	margin-bottom: 5px;
	margin-top: 5px;
	display: flex;
	align-items: center;
}

.contractor-data i {
	font-size: 2rem;
	margin-right: 1rem;
	cursor: pointer;
}
.flaticon-pin {
	color: #ff6b00;
}

.location-header-container {
	margin-top: 1rem !important;
}

@media only screen and (max-width: 768px) {
	.project-card-container {
		grid-template-columns: 2.5fr 3.8fr 0.1fr 0.1fr 1fr;
		width: 90%;
	}

	.project-card-container .control-logos img {
		width: 70%;
	}
}

@media only screen and (max-width: 500px) {
	.project-card-container {
		display: block;
		padding-bottom: 2rem;
	}
	.main-image {
		box-shadow: none;
	}

	.project-card-container .control-logos img {
		width: 13%;
	}
}

.tooltip {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* Tooltip text */
.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	background-color: rgb(76, 76, 76);
	color: #fff;
	text-align: center;
	padding: 5px 0;
	border-radius: 6px;
	font-size: 18px;
	font-weight: 500;

	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 1;
	top: 40px;
	left: -50%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
	visibility: visible;
}

/** * I was out the other evening looking at Venus with the setting sun and thought, I wonder where all the planets * are in relation to each other right now. I knew what an Orrery was, but I'd never built one. So, given my mate * Donovan's (@donovanh: http://cssanimation.rocks/) penchant for CSS animation, I thought I'd give it a go * building one in pure CSS. * * Many thanks to @aidandore and @iandevlin too for suggestions and improvements * * Chin up Pluto. You'll always be a planet to me... * * Tady: http://tady.me * @tadywankenobi */
/** * Move in a circle without wrapper elements * Idea by Aryeh Gregor, simplified by Lea Verou, borrowed by me! */
.loading-page {
	background-color: #012;
	background-size: 33%;
	background-repeat: repeat;
	min-height: 100vh;
	overflow: hidden;
}
.system {
	position: relative;
	top: -60vh;
	left: 0;
	width: 100%;
	height: 100%;
	transform: scale(1);
}
.sun {
	width: 144px;
	height: 144px;
	border-radius: 72px;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -72px;
	background-image: url("http://sdo.gsfc.nasa.gov/assets/img/latest/latest_256_HMIIF.jpg");
	background-size: 144px;
	background-repeat: no-repeat;
}
@keyframes rot-mer {
	from {
		-webkit-transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
@keyframes rot-mer {
	from {
		-moz-transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
@keyframes rot-mer {
	from {
		transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
.mer {
	width: 3.5px;
	height: 3.5px;
	border-radius: 50%;
	background-color: #888;
	position: absolute;
	top: 800px;
	right: 50%;
	margin: -1.75px;
	animation: rot-mer 4.4s infinite linear;
	z-index: 200;
}
.mer-path {
	width: 168px;
	height: 168px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -84px;
	border: solid 1px #444;
}
@keyframes rot-ven {
	from {
		-webkit-transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
@keyframes rot-ven {
	from {
		-moz-transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
@keyframes rot-ven {
	from {
		transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
.ven {
	width: 5.5px;
	height: 5.5px;
	border-radius: 50%;
	background-color: #f5f9be;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -2.75px;
	animation: rot-ven 11.25s infinite linear;
	z-index: 200;
}
.ven-path {
	width: 180px;
	height: 180px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -90px;
	border: solid 1px #444;
}
@keyframes rot-ear {
	from {
		-webkit-transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
@keyframes rot-ear {
	from {
		-moz-transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
@keyframes rot-ear {
	from {
		transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
.ear {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: #4b94f9;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -3.5px;
	animation: rot-ear 18.25s infinite linear;
	z-index: 200;
}
.ear-path {
	width: 204px;
	height: 204px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -102px;
	border: solid 1px #444;
}
@keyframes rot-mar {
	from {
		-webkit-transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
@keyframes rot-mar {
	from {
		-moz-transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
@keyframes rot-mar {
	from {
		transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
.mar {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #dd411a;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -3px;
	animation: rot-mar 34.35s infinite linear;
	z-index: 200;
}
.mar-path {
	width: 236px;
	height: 236px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -118px;
	border: solid 1px #444;
}
@keyframes rot-jup {
	from {
		-webkit-transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
@keyframes rot-jup {
	from {
		-moz-transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
@keyframes rot-jup {
	from {
		transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
.jup {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: #eaad3b;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -35px;
	animation: rot-jup 216.6s infinite linear;
	z-index: 200;
}
.jup-path {
	width: 456px;
	height: 456px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -228px;
	border: solid 1px #444;
}
@keyframes rot-sat {
	from {
		-webkit-transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
@keyframes rot-sat {
	from {
		-moz-transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
@keyframes rot-sat {
	from {
		transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
.sat {
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-color: #d6cd93;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -29px;
	animation: rot-sat 537.95s infinite linear;
	z-index: 200;
}
.sat-path {
	width: 724px;
	height: 724px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -362px;
	border: solid 1px #444;
}
@keyframes rot-ura {
	from {
		-webkit-transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
@keyframes rot-ura {
	from {
		-moz-transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
@keyframes rot-ura {
	from {
		transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
.ura {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: #bfeef2;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -13px;
	animation: rot-ura 1534.35s infinite linear;
	z-index: 200;
}
.ura-path {
	width: 1296px;
	height: 1296px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -648px;
	border: solid 1px #444;
}
@keyframes rot-nep {
	from {
		-webkit-transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
@keyframes rot-nep {
	from {
		-moz-transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
@keyframes rot-nep {
	from {
		transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
.nep {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #363ed7;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -12px;
	animation: rot-nep 3009.5s infinite linear;
	z-index: 200;
}
.nep-path {
	width: 1944px;
	height: 1944px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -972px;
	border: solid 1px #444;
}
@keyframes rot-plu {
	from {
		-webkit-transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
@keyframes rot-plu {
	from {
		-moz-transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
@keyframes rot-plu {
	from {
		transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
.plu {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #963;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -1.5px;
	animation: rot-plu 4523.25s infinite linear;
	z-index: 200;
}
.plu-path {
	width: 2492px;
	height: 2492px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -1246px;
	border: solid 1px #444;
}
@keyframes rot-lune {
	from {
		-webkit-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-lune {
	from {
		-moz-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-lune {
	from {
		transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
.lune {
	width: 2px;
	height: 2px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -1.5px;
	animation: rot-lune 1.35s infinite linear;
}
.mar {
	background-image: repeating-linear-gradient(
		to bottom,
		#fff,
		#fff 1px,
		transparent 1px,
		transparent 5px
	);
}
@keyframes rot-pho {
	from {
		-webkit-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-pho {
	from {
		-moz-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-pho {
	from {
		transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		-webkit-transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		-moz-transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
.pho,
.dem {
	width: 1px;
	height: 1px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
}
.pho {
	margin: -1px;
	animation: rot-pho 0.75s infinite linear;
}
.dem {
	margin: -1px;
	animation: rot-dem 1s infinite linear;
}
.jove {
	width: 2px;
	height: 2px;
	background-color: #fff;
	position: absolute;
	top: 35px;
	left: 50%;
}
@keyframes rot-io {
	from {
		-webkit-transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-io {
	from {
		-moz-transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-io {
	from {
		transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		-webkit-transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		-moz-transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		-webkit-transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		-moz-transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		-webkit-transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		-moz-transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
.io {
	animation: rot-io 1s infinite linear;
}
.eur {
	animation: rot-eur 1.75s infinite linear;
}
.gan {
	animation: rot-gan 3.5s infinite linear;
}
.cal {
	animation: rot-cal 8.25s infinite linear;
}
.jup {
	background-image: repeating-linear-gradient(
		6deg,
		#797663 22px,
		#e1dcde 16px,
		#c3a992 30px,
		#e9ece2 30px
	);
}
.spot {
	position: absolute;
	width: 16px;
	height: 12px;
	border-radius: 8px / 6px;
	top: 45px;
	left: 50%;
	background-color: #bc833b;
	box-shadow: 0px 0px 5px #e1dcde;
	border: solid 1px #e1dcde;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	z-index: 300;
}
.nep .spot {
	background-color: #29319d;
	border: 0;
	box-shadow: none;
	top: 50%;
	left: 45%;
	width: 10px;
	height: 6px;
	margin: -2px;
	border-radius: 5px / 3px;
	border-left: solid 1px #7ed6fe;
}
div[class$="-ring"] {
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0.7;
	transform: rotatex(45deg);
}
.a-ring {
	border: solid 5px #96866f;
	width: 119px;
	height: 119px;
	margin: -64.5px;
}
.b-ring {
	border: solid 10px #554c3c;
	width: 104px;
	height: 104px;
	margin: -62px;
}
.c-ring {
	border: solid 9px #574f4a;
	width: 95px;
	height: 95px;
	margin: -56.5px;
}
.f-ring {
	border: solid 2px #908e8d;
	width: 133px;
	height: 133px;
	margin: -68.5px;
}
.e-ring {
	border: solid 7px #908e8d;
	width: 76px;
	height: 76px;
	margin: -45px;
	transform: rotatex(0deg) rotatey(89deg) !important;
}
.plu,
.plu-path {
	top: 1087.4px;
}

.hero-page {
	height: 27vh;
	width: 100vw;
	max-width: 100%;
	background: url(/static/media/HeroBackground.73596915.jpeg) center no-repeat;
	background-size: cover;
}

.hero-page-shadow {
	background: transparent
		linear-gradient(270deg, #000000 0%, #000000c4 33%, #000000 77%) 0% 0%
		no-repeat padding-box;
	opacity: 0.4;
	width: 100%;
	height: 27vh;
}

.make-an-offer-conatiner {
	display: grid;
	justify-content: center;
	align-items: center;
	margin: 3%;
	position: relative;
}

.project-card-for-contractor-make-an-offer {
	grid-template-columns: 2fr 3.3fr 0.1fr 0.1fr 2fr;
}

.project-card-container-contractor-card {
	min-height: 250px;
}
.make-an-offer-conatiner h4 {
	font-weight: 500;
}
.bold-offer-data {
	font-weight: 600;
}

.offer-data {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 3%;
}

@media only screen and (max-width: 500px) {
	.offer-data {
		position: relative;
		text-align: center;
	}
}

.profile-container .description {
	width: 65%;
	margin: 0.5rem auto;
	padding: 1rem 0;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: -5vh;
	z-index: 5;
}

.profile-container .description h3 {
	margin-bottom: 0.5rem;
	border-bottom: 1px solid #cccccc;
	padding: 3% 4%;
	padding-left: 0;
	margin: 0 5%;
	font-size: 1.6rem;
	font-weight: 700;
}

.profile-container .description p {
	margin: 3% 5%;
	font-size: 1.2rem;
	letter-spacing: 0.04rem;
	font-weight: 400;
}

@media only screen and (max-width: 768px) {
	.profile-container .description {
		width: 90%;
	}
}

.project-wrapper {
	background: rgba(211, 211, 211, 0.384);
	background-position: center;
}

.project-container {
	margin: 0 auto;
	width: 100%;
	/* box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%); */
	border-radius: 10px;
	/* background: rgba(0, 0, 0, 0.089); */
	display: flex;
	flex-direction: column;
	padding-top: 100px;
}

.project-img {
	width: 100%;
	border-radius: 10px 10px 0 0;
}

.project-section-header {
	font-family: "Poppins";
	font-weight: 900;
	padding: 3% 0;
	border-bottom: solid 1px #cccccc;
	font-size: 1.4rem;
	letter-spacing: 0.05;
}

.white-section {
	background: white;
	border-radius: 10px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	padding: 0 4%;
	padding-top: 1%;
	margin-bottom: 2% 0;
	padding-bottom: 2%;
	margin-bottom: 5%;
}

.project-img-container {
	margin-bottom: 2%;
	width: 20%;
}

.project-container .profile-right .detail {
	font-size: 1.4rem;
}

.project-container .profile-right .detail i {
	font-size: 1.7rem;
}

.project-overview-container {
	width: 100%;
	margin: 0 auto;
}

.available-project-overview-container {
	width: 100%;
	margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
	.project-section-header {
		font-size: 1.7rem;
	}
}

@media only screen and (max-width: 768px) {
	.project-overview-container {
		width: 90%;
	}
}

@media only screen and (max-width: 768px) {
	.project-section-header {
		font-size: 1rem;
	}
}

.company-img {
	width: 100%;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #ff5722 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: #ff9800 !important;
}

@media only screen and (max-width: 768px) {
	.company-img {
		width: 100%;
	}
}

@media only screen and (max-width: 500px) {
	.company-img {
		width: 100%;
	}
}

.rating-company {
	color: #ff6b00;
	font-size: larger;
}

.give-review-container h4 {
	font-size: larger;
}

.explanation {
	display: grid;
	grid-gap: 5%;
	gap: 5%;
	margin-top: 5%;
	justify-content: center;
}

@media only screen and (max-width: 768px) {
	.explanation {
		grid-template-columns: 1fr;
		grid-gap: 0%;
		gap: 0%;
		margin-top: 0%;
	}
}

.give-sun-rating-container {
	margin-top: 2% !important;
}

.give-sun-rating-container fieldset {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* .scale-project-container {
	display: grid;
	margin: 4% 0;
	border-top: 1px solid gray;
	padding: 4% 0;
	border-bottom: 1px solid gray;
} */

.scale-slider-container {
	display: flex;
	align-items: center;
	margin-bottom: 3%;
	margin-top: 3%;
}

.scale-slider-container:first-child {
	width: 90% !important;
	padding-right: 5%;
}

.scale-button {
	width: 70%;
	margin-right: 0;
}

.scale-button .makeStyles-button-13 {
	font-size: 1rem !important;
}

.scale-button .flaticon-scale {
	font-size: 1.4rem !important;
}

.scale-project-heading {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin-left: 10%;
}

@media only screen and (max-width: 1000px) {
	.scale-button .makeStyles-button-13 {
		font-size: 1.3rem !important;
	}
}

@media only screen and (max-width: 500px) {
	.scale-project-container {
		grid-template-columns: 1fr;
	}

	.scale-button .makeStyles-button-13 {
		font-size: 0.7rem !important;
	}

	.scale-button {
		margin: 0 auto;
		align-items: center;
		justify-content: center;
	}
	.scale-button:first-child {
		font-size: 0.7em;
	}

	.makeStyles-root-52 {
		width: 85% !important;
	}
}

.offer-card-container {
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	margin: 6vh auto;
	display: grid;
	grid-template-columns: 1.5fr 3.8fr 0.1fr 0.1fr 2fr;
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	background: white;
	min-height: 220px;
}

.offer-card-container img {
	border-radius: 15px;
	width: 100%;
	height: 110%;
	object-fit: cover;
	overflow: hidden;
	position: relative;
	top: -5%;
}

.offer-card-container .main-image {
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.offer-card-container .project-data {
	margin-left: 35px;
	flex-direction: column;
	display: flex;
}

.offer-card-container h2 {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 1px;
	margin: 10px 0px;
	margin-bottom: 20px;
	padding: 5px;
}

.offer-card-container .project-rating-container {
	width: 80%;
}

.offer-card-container .location-container {
	margin: 15px -10px;
}

.offer-card-container .location-container .main-image {
	width: 40%;
}

.offer-card-container .location-container h4 {
	font-weight: 500;
}

.offer-card-container .control-logos {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10% 0;
}

.offer-card-container .control-logos img {
	width: 60%;
	height: auto;
	border-radius: 0;
	cursor: pointer;
}

.offer-card-container .status {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 500;
}

.offer-card-container .project {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.offer-card-container .project-data h4 {
	font-weight: 600;
	font-size: 15px;
}

.offer-card-container .user-container {
	margin: auto 0;
}

.offer-card-container .location-continer {
	display: grid;
	width: 70%;
	grid-template-columns: 1fr 4fr;
	margin-top: 10px;
}

.offer-card-container .location-text {
	align-self: center;
}

.offer-card-container .location-continer .main-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.offer-card-container .contractor-data {
	margin: auto 2%;
	margin-top: 5%;
	margin-bottom: 14%;
}

.offer-card-container .contractor-data h3 {
	margin-bottom: 7px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 1.3em;
}

.offer-card-container .contractor-data i {
	font-size: 2rem;
	margin-right: 1rem;
	cursor: pointer;
}

.offer-card-container p {
	font-size: 1rem;
	color: rgb(0, 0, 0);
	margin-top: 5px;
}

.view-offer-details {
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.offer-card-container {
		grid-template-columns: 1fr;
		width: 100%;
		font-size: 1.5rem;
	}
	.offer-card-container .control-logos {
		flex-direction: row;
		margin: 5% 0;
	}

	.offer-card-container .control-logos > * {
		margin: 3%;
	}

	.offer-card-container h2 {
		margin-top: 4rem;
		font-size: 2.4rem;
	}

	.offer-card-container .project-data h4 {
		font-weight: 600;
		font-size: 20px;
	}

	.offer-card-container p {
		font-size: 1.2rem;
	}
	@media only screen and (max-width: 500px) {
		.offer-card-container .contractor-data h3 {
			font-size: 1.3rem;
		}
		.offer-card-container h2 {
			font-size: 2rem;
		}
		.offer-card-container .project-data h4 {
			font-size: 17px;
		}
	}
}

.offer-details-modal-data .output-data-row .output-label {
	color: white;
	margin-left: 7%;
}

.offer-details-modal-data .output-data-row .output-field {
	font-size: 1.2rem;
	padding-right: 7%;
}

.offer-details-modal-data .output-data-row .unit {
	font-size: 1.2rem;
}

.offer-details-modal-data {
	width: 80vw !important;
}

.offer-details-modal-data .output-data-row .output-and-label {
	margin: 4% 0;
}

.offer-details-modal-data h2 {
	width: 90%;
}

.offer-details-modal-data textarea {
	height: 240px !important;
	background: #ffffff45;
	color: white;
}

.offer-details-modal-data .unit {
	margin-left: 0px;
}

.offer-details-modal-data .unit {
	margin-left: 0px;
}

.offer-details-modal-data .output-data-row .unit {
	margin-left: 0px;
}

.offer-details-modal-data .output-data-row .output-and-label {
	grid-template-columns: 1fr 1.3fr;
}
.offer-details-modal-data .output-data-row .output-fields {
	width: 90%;
}

.project-timeline-container .timeline-grid h3 {
	font-weight: 400;
}

.project-timeline-container .timeline-grid {
	display: flex;
}

.project-timeline-container .ordinal-number {
	font-size: 1.7rem;
	display: flex;
	justify-content: flex-end;
	align-items: flex-start;
	margin-right: 0.5rem;
	font-weight: 700 !important;
}

.project-timeline-container .timeline-item-text {
	text-align: left;
	color: #666;
}

.MuiTimeline-root {
	border: 0 !important;
}

.timeline-control-buttons {
	margin: 0 5%;
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 768px) {
	.MuiTimelineOppositeContent-root {
		display: none !important;
	}
	.MuiTimelineItem-missingOppositeContent:before {
		display: none !important;
	}
	.MuiTimelineItem-alignAlternate:nth-child(even) {
		flex-direction: row !important;
	}
	.project-timeline-container .timeline-grid h3 {
		font-size: 1rem;
	}
	.project-timeline-container {
		padding-left: 5%;
	}
}

.file-card-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.file-icon-container {
	display: grid;
	width: 30%;
	grid-template-columns: 1fr 5fr;
	justify-content: center;
	align-items: center;
	justify-self: flex-start;
	margin-right: 20%;
}

.file-card-container h3 {
	padding-left: 1rem;
}

.project-documentation-container .fa-lg {
	font-size: 1.5em !important;
}

.project-documentation-container p {
	color: #666;
	font-weight: 400;
	font-family: "Poppins";
}

.files-container {
	margin: 3vh 0;
}

.files-container .MuiAvatar-root {
	border-radius: 13%;
}

.files-container .MuiListItem-container::marker {
	display: none;
	color: transparent;
}

.files-container .MuiAvatar-root {
	height: 47px;
}

.terms-container {
	border-radius: 10px;
	margin-top: -12vh;
	z-index: 5;
	background: white;
	box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
	padding: 5%;
	text-align: justify;
	margin-bottom: 5vh;
}

.terms-wrapper .profile-container {
	grid-template-columns: 1fr !important;
}

.terms-of-use-bullet {
	margin-left: 5%;
	list-style-type: none;
}

.MuiTypography-gutterBottom {
	margin-top: 0.5em;
}

.hero-not-found .hero-headline {
	width: 100%;
	font-size: 20rem;
}

.hero-not-found h3 {
	color: white;
	font-size: 2rem;
	margin-left: 2rem;
}

.hero-not-found h2 {
	color: white;
	font-size: 3rem;
	margin-left: 2rem;
}

.hero-not-found .hero-container {
	margin-top: 0;
}

.first-row-container {
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
	width: 70%;
	margin-bottom: 10vh;
}

.hero-not-found {
	background-image: url(/static/media/broken-solar.149ccaf3.svg);
	background-size: cover;
	background-repeat: no-repeat;
}

#under-constraction {
	background-image: url(/static/media/UnderConstruction.8bca80c0.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}

.knowledgemenu .profile-up {
	margin-top: 4vh;
	align-items: start;
}

.knowledgemenu .profile-down {
	margin-top: 3%;
}

.title-container-knowledgemenu {
	display: grid;
	grid-template-columns: 1fr 5fr;
	margin: 3%;
}

.Mui-expanded {
	margin: 0 !important;
}

.profile-up h2 {
	margin-top: 1.2rem;
}

.profile-up h3 {
	margin-left: 1rem;
}

.profile-up {
	padding-left: 1rem;
}

.knowledge-menu .MuiAccordion-rounded::before {
	display: none;
	margin: 0;
}

.knowledge-menu .MuiAccordion-root:last-of-type {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.click-element {
	cursor: pointer;
}

.knowledge-menu .MuiAccordionSummary-content {
	margin: 0;
	padding: 16px 0;
}

.knowledge-menu .MuiAccordion-root {
	border-bottom: solid 1px #958c8c;
}

li::marker {
	color: #f37021;
}

.profile-wrapper .profile-container .knowledgemenu {
	grid-template-columns: 1.3fr 5fr;
	width: 90%;
}

.knowledgemenu h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 2.5rem;
}

.knowledgemenu .profile-container {
	width: 90%;
}

.knowledgemenu ul {
	list-style-type: square;
	list-style-position: inside;
}

.knowledgemenu ul li::before {
	color: "#F37021";
}

.knowledgemenu {
	background-color: white;
}

.knowledgemenu .project-data-margin-top {
	margin-top: -12vh;
}

.knowledgemenu .financing-page .project-data-margin-top {
	margin-top: -20vh;
}

.financing-page li {
	font-size: 1.2rem;
}

.financing-page li::marker {
	color: #000;
}

.knowledgemenu .white-section {
	padding: 6% 9%;
}

.knowledgemenu .financing-page .white-section p {
	font-size: 1.2rem;
	text-align: justify;
	font-weight: 400;
}

.knowledgemenu .financing-page .white-section a p {
	color: blue;
}

.financing-page .city-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.knowledgemenu .white-section p {
	font-size: 1.6rem;
	text-align: justify;
	font-weight: 400;
}

.knowledgemenu .white-section h3 {
	margin-top: 5%;
	margin-bottom: 3%;
	color: #f37021;
	font-size: 1.9rem;
}

.knowledgemenu .white-section h3::before {
	content: "•";
	display: inline-block;
	margin-right: 15px;
	color: #f37021;
}

body .profile-wrapper .financing-page {
	grid-template-columns: 1fr;
}

.knowledgemenu .MuiTypography-h5 {
	font-weight: 700;
}
.knowledgemenu .white-section h1 {
	margin-bottom: 4rem;
}
.indend {
	margin-left: 4%;
}
.MuiTableCell-head {
	font-weight: bold !important;
}
.MuiTableCell-root {
	font-size: 1.4rem !important;
}

@media only screen and (max-width: 768px) {
	.knowledgemenu .project-data-margin-top {
		margin-top: 0vh;
	}
	.MuiTableCell-root {
		padding: 8px !important;
	}
	.knowledgemenu .white-section p {
		font-size: 1.1rem;
	}
	.MuiTableCell-root {
		font-size: 1.1rem !important;
	}
	.knowledgemenu .white-section h3 {
		font-size: 1.3rem;
	}
	.knowledgemenu h1 {
		font-size: 2rem;
	}

	.knowledgemenu .white-section h1 {
		margin-bottom: 2.5rem;
	}
}

@media only screen and (max-width: 400px) {
	.MuiTableCell-root {
		padding: 3px !important;
	}
	.knowledgemenu .white-section p {
		font-size: 0.8rem;
	}

	.financing-page li {
		font-size: 1rem;
	}

	.financing-page .city-grid {
		grid-template-columns: 1fr 1fr;
	}

	.knowledgemenu .financing-page .white-section p {
		font-size: 1rem;
	}
	.MuiTableCell-root {
		font-size: 0.8rem !important;
	}
	.knowledgemenu .white-section h3 {
		font-size: 1.1rem;
	}
}

.image-container {
	width: 80%;
	max-width: 100%;
	overflow: hidden;
	margin: 5% auto;
}
.narrow {
	width: 100% !important;
}
.image-container img {
	width: 100%;
	height: auto;
	display: block;
}

.image-container .center {
	text-align: center !important;
	margin-top: 8px;
	font-weight: 500;
	font-size: smaller;
}

@media only screen and (max-width: 768px) {
	.image-container {
		width: 100%;
	}
}

.regulative-country-select {
	display: grid;
	grid-template-columns: 1fr 5fr;
	align-items: center;
	justify-content: center;
}

.regulative-files-table .regulative-grid {
	display: grid;
	grid-template-columns: 0.7fr 3fr 0.4fr;
	padding: 20px 0px;
	border: #958c8c 1px solid;
	margin: 20px 0;
	align-items: center;
	justify-items: center;
}

.regulative-files-table .regulative-grid h3 {
	color: #858583;
	font-weight: 700;
}

.regulative-data {
	justify-self: self-start;
}

.regulative-border {
	border-bottom: solid 3px #f37021;
	margin-bottom: 10px;
}

.regulative-fileicon-container {
	width: 30%;
	margin: auto;
}

