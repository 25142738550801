.company-img {
	width: 100%;
}

.MuiSwitch-colorPrimary.Mui-checked {
	color: #ff5722 !important;
}

.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
	background-color: #ff9800 !important;
}

@media only screen and (max-width: 768px) {
	.company-img {
		width: 100%;
	}
}

@media only screen and (max-width: 500px) {
	.company-img {
		width: 100%;
	}
}
