/** * I was out the other evening looking at Venus with the setting sun and thought, I wonder where all the planets * are in relation to each other right now. I knew what an Orrery was, but I'd never built one. So, given my mate * Donovan's (@donovanh: http://cssanimation.rocks/) penchant for CSS animation, I thought I'd give it a go * building one in pure CSS. * * Many thanks to @aidandore and @iandevlin too for suggestions and improvements * * Chin up Pluto. You'll always be a planet to me... * * Tady: http://tady.me * @tadywankenobi */
/** * Move in a circle without wrapper elements * Idea by Aryeh Gregor, simplified by Lea Verou, borrowed by me! */
.loading-page {
	background-color: #012;
	background-size: 33%;
	background-repeat: repeat;
	min-height: 100vh;
	overflow: hidden;
}
.system {
	position: relative;
	top: -60vh;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-transform: scale(1);
	-moz-transform: scale(1);
	transform: scale(1);
}
.sun {
	width: 144px;
	height: 144px;
	border-radius: 72px;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -72px;
	background-image: url("http://sdo.gsfc.nasa.gov/assets/img/latest/latest_256_HMIIF.jpg");
	background-size: 144px;
	background-repeat: no-repeat;
}
@keyframes rot-mer {
	from {
		-webkit-transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
@keyframes rot-mer {
	from {
		-moz-transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
@keyframes rot-mer {
	from {
		transform: rotate(0deg) translatey(-84px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-84px) rotate(-360deg);
	}
}
.mer {
	width: 3.5px;
	height: 3.5px;
	border-radius: 50%;
	background-color: #888;
	position: absolute;
	top: 800px;
	right: 50%;
	margin: -1.75px;
	-webkit-animation: rot-mer 4.4s infinite linear;
	-moz-animation: rot-mer 4.4s infinite linear;
	animation: rot-mer 4.4s infinite linear;
	z-index: 200;
}
.mer-path {
	width: 168px;
	height: 168px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -84px;
	border: solid 1px #444;
}
@keyframes rot-ven {
	from {
		-webkit-transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
@keyframes rot-ven {
	from {
		-moz-transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
@keyframes rot-ven {
	from {
		transform: rotate(0deg) translatey(-90px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-90px) rotate(-360deg);
	}
}
.ven {
	width: 5.5px;
	height: 5.5px;
	border-radius: 50%;
	background-color: #f5f9be;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -2.75px;
	-webkit-animation: rot-ven 11.25s infinite linear;
	-moz-animation: rot-ven 11.25s infinite linear;
	animation: rot-ven 11.25s infinite linear;
	z-index: 200;
}
.ven-path {
	width: 180px;
	height: 180px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -90px;
	border: solid 1px #444;
}
@keyframes rot-ear {
	from {
		-webkit-transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
@keyframes rot-ear {
	from {
		-moz-transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
@keyframes rot-ear {
	from {
		transform: rotate(0deg) translatey(-102px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-102px) rotate(-360deg);
	}
}
.ear {
	width: 7px;
	height: 7px;
	border-radius: 50%;
	background-color: #4b94f9;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -3.5px;
	-webkit-animation: rot-ear 18.25s infinite linear;
	-moz-animation: rot-ear 18.25s infinite linear;
	animation: rot-ear 18.25s infinite linear;
	z-index: 200;
}
.ear-path {
	width: 204px;
	height: 204px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -102px;
	border: solid 1px #444;
}
@keyframes rot-mar {
	from {
		-webkit-transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
@keyframes rot-mar {
	from {
		-moz-transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
@keyframes rot-mar {
	from {
		transform: rotate(0deg) translatey(-118px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-118px) rotate(-360deg);
	}
}
.mar {
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #dd411a;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -3px;
	-webkit-animation: rot-mar 34.35s infinite linear;
	-moz-animation: rot-mar 34.35s infinite linear;
	animation: rot-mar 34.35s infinite linear;
	z-index: 200;
}
.mar-path {
	width: 236px;
	height: 236px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -118px;
	border: solid 1px #444;
}
@keyframes rot-jup {
	from {
		-webkit-transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
@keyframes rot-jup {
	from {
		-moz-transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
@keyframes rot-jup {
	from {
		transform: rotate(0deg) translatey(-228px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-228px) rotate(-360deg);
	}
}
.jup {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	background-color: #eaad3b;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -35px;
	-webkit-animation: rot-jup 216.6s infinite linear;
	-moz-animation: rot-jup 216.6s infinite linear;
	animation: rot-jup 216.6s infinite linear;
	z-index: 200;
}
.jup-path {
	width: 456px;
	height: 456px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -228px;
	border: solid 1px #444;
}
@keyframes rot-sat {
	from {
		-webkit-transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
@keyframes rot-sat {
	from {
		-moz-transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
@keyframes rot-sat {
	from {
		transform: rotate(0deg) translatey(-362px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-362px) rotate(-360deg);
	}
}
.sat {
	width: 58px;
	height: 58px;
	border-radius: 50%;
	background-color: #d6cd93;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -29px;
	-webkit-animation: rot-sat 537.95s infinite linear;
	-moz-animation: rot-sat 537.95s infinite linear;
	animation: rot-sat 537.95s infinite linear;
	z-index: 200;
}
.sat-path {
	width: 724px;
	height: 724px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -362px;
	border: solid 1px #444;
}
@keyframes rot-ura {
	from {
		-webkit-transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
@keyframes rot-ura {
	from {
		-moz-transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
@keyframes rot-ura {
	from {
		transform: rotate(0deg) translatey(-648px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-648px) rotate(-360deg);
	}
}
.ura {
	width: 26px;
	height: 26px;
	border-radius: 50%;
	background-color: #bfeef2;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -13px;
	-webkit-animation: rot-ura 1534.35s infinite linear;
	-moz-animation: rot-ura 1534.35s infinite linear;
	animation: rot-ura 1534.35s infinite linear;
	z-index: 200;
}
.ura-path {
	width: 1296px;
	height: 1296px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -648px;
	border: solid 1px #444;
}
@keyframes rot-nep {
	from {
		-webkit-transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
@keyframes rot-nep {
	from {
		-moz-transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
@keyframes rot-nep {
	from {
		transform: rotate(0deg) translatey(-972px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-972px) rotate(-360deg);
	}
}
.nep {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background-color: #363ed7;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -12px;
	-webkit-animation: rot-nep 3009.5s infinite linear;
	-moz-animation: rot-nep 3009.5s infinite linear;
	animation: rot-nep 3009.5s infinite linear;
	z-index: 200;
}
.nep-path {
	width: 1944px;
	height: 1944px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -972px;
	border: solid 1px #444;
}
@keyframes rot-plu {
	from {
		-webkit-transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
@keyframes rot-plu {
	from {
		-moz-transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
@keyframes rot-plu {
	from {
		transform: rotate(0deg) translatey(-1246px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-1246px) rotate(-360deg);
	}
}
.plu {
	width: 3px;
	height: 3px;
	border-radius: 50%;
	background-color: #963;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -1.5px;
	-webkit-animation: rot-plu 4523.25s infinite linear;
	-moz-animation: rot-plu 4523.25s infinite linear;
	animation: rot-plu 4523.25s infinite linear;
	z-index: 200;
}
.plu-path {
	width: 2492px;
	height: 2492px;
	border-radius: 50%;
	z-index: 100;
	position: absolute;
	top: 800px;
	left: 50%;
	margin: -1246px;
	border: solid 1px #444;
}
@keyframes rot-lune {
	from {
		-webkit-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-lune {
	from {
		-moz-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-lune {
	from {
		transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
.lune {
	width: 2px;
	height: 2px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -1.5px;
	-webkit-animation: rot-lune 1.35s infinite linear;
	-moz-animation: rot-lune 1.35s infinite linear;
	animation: rot-lune 1.35s infinite linear;
}
.mar {
	background-image: repeating-linear-gradient(
		to bottom,
		#fff,
		#fff 1px,
		transparent 1px,
		transparent 5px
	);
}
@keyframes rot-pho {
	from {
		-webkit-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-pho {
	from {
		-moz-transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-pho {
	from {
		transform: rotate(0deg) translatey(-7px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-7px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		-webkit-transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		-moz-transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
@keyframes rot-dem {
	from {
		transform: rotate(0deg) translatey(-9px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-9px) rotate(-360deg);
	}
}
.pho,
.dem {
	width: 1px;
	height: 1px;
	background-color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
}
.pho {
	margin: -1px;
	-webkit-animation: rot-pho 0.75s infinite linear;
	-moz-animation: rot-pho 0.75s infinite linear;
	animation: rot-pho 0.75s infinite linear;
}
.dem {
	margin: -1px;
	-webkit-animation: rot-dem 1s infinite linear;
	-moz-animation: rot-dem 1s infinite linear;
	animation: rot-dem 1s infinite linear;
}
.jove {
	width: 2px;
	height: 2px;
	background-color: #fff;
	position: absolute;
	top: 35px;
	left: 50%;
}
@keyframes rot-io {
	from {
		-webkit-transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-io {
	from {
		-moz-transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-io {
	from {
		transform: rotate(0deg) translatey(-39px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-39px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		-webkit-transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		-moz-transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-eur {
	from {
		transform: rotate(0deg) translatey(-41px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-41px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		-webkit-transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		-moz-transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-gan {
	from {
		transform: rotate(0deg) translatey(-45px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-45px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		-webkit-transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		-moz-transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
@keyframes rot-cal {
	from {
		transform: rotate(0deg) translatey(-53px) rotate(0deg);
	}
	to {
		transform: rotate(360deg) translatey(-53px) rotate(-360deg);
	}
}
.io {
	-webkit-animation: rot-io 1s infinite linear;
	-moz-animation: rot-io 1s infinite linear;
	animation: rot-io 1s infinite linear;
}
.eur {
	-webkit-animation: rot-eur 1.75s infinite linear;
	-moz-animation: rot-eur 1.75s infinite linear;
	animation: rot-eur 1.75s infinite linear;
}
.gan {
	-webkit-animation: rot-gan 3.5s infinite linear;
	-moz-animation: rot-gan 3.5s infinite linear;
	animation: rot-gan 3.5s infinite linear;
}
.cal {
	-webkit-animation: rot-cal 8.25s infinite linear;
	-moz-animation: rot-cal 8.25s infinite linear;
	animation: rot-cal 8.25s infinite linear;
}
.jup {
	background-image: repeating-linear-gradient(
		6deg,
		#797663 22px,
		#e1dcde 16px,
		#c3a992 30px,
		#e9ece2 30px
	);
}
.spot {
	position: absolute;
	width: 16px;
	height: 12px;
	border-radius: 8px / 6px;
	top: 45px;
	left: 50%;
	background-color: #bc833b;
	box-shadow: 0px 0px 5px #e1dcde;
	border: solid 1px #e1dcde;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	z-index: 300;
}
.nep .spot {
	background-color: #29319d;
	border: 0;
	box-shadow: none;
	top: 50%;
	left: 45%;
	width: 10px;
	height: 6px;
	margin: -2px;
	border-radius: 5px / 3px;
	border-left: solid 1px #7ed6fe;
}
div[class$="-ring"] {
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0.7;
	-webkit-transform: rotatex(45deg);
	-moz-transform: rotatex(45deg);
	transform: rotatex(45deg);
}
.a-ring {
	border: solid 5px #96866f;
	width: 119px;
	height: 119px;
	margin: -64.5px;
}
.b-ring {
	border: solid 10px #554c3c;
	width: 104px;
	height: 104px;
	margin: -62px;
}
.c-ring {
	border: solid 9px #574f4a;
	width: 95px;
	height: 95px;
	margin: -56.5px;
}
.f-ring {
	border: solid 2px #908e8d;
	width: 133px;
	height: 133px;
	margin: -68.5px;
}
.e-ring {
	border: solid 7px #908e8d;
	width: 76px;
	height: 76px;
	margin: -45px;
	-webkit-transform: rotatex(0deg) rotatey(89deg) !important;
	-moz-transform: rotatex(0deg) rotatey(89deg) !important;
	transform: rotatex(0deg) rotatey(89deg) !important;
}
.plu,
.plu-path {
	top: 1087.4px;
}
