.bar-chart-container {
	width: 80%;
	margin: 0 auto;
	padding-top: 2%;
}

@media only screen and (max-width: 1000px) {
	.bar-chart-container {
		width: 75%;
	}
}
