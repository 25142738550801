.MuiPaper-elevation1 {
	box-shadow: none !important;
}

.MuiExpansionPanelSummary-root {
	padding: 0 1% !important;
}

.expansion-panels-container h4 {
	font-weight: 500;
	color: #5e5e5e;
}

.MuiExpansionPanelDetails-root {
	padding: 0 !important;
}
.MuiAccordionDetails-root {
	display: block !important;
	padding: 0 !important;
}
.MuiExpansionPanelDetails-root {
	display: block !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
	min-height: 30px !important;
}
