@font-face {
	font-family: Antipasto;
	src: url("../../../Assets/fonts/antipasto.demibold.ttf");
}
body {
	background: rgba(78, 78, 78, 0.022);
}
.hero-welcome {
	width: 100%;
	height: calc(var(--vh, 1vh) * 100);
	background: url(../../../Assets/Images/HeroBackground.jpeg) center no-repeat;
	background-size: cover;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: flex-start;
	flex-direction: column;
	position: relative;
}
a {
	text-decoration: none;
}

.hero-container {
	margin-left: 7vw;
	margin-top: -5vh;
	z-index: 2;
}

.right-hero-shadow {
	width: 60vw;
	height: calc(var(--vh, 1vh) * 100);
	background: transparent
		linear-gradient(270deg, #00000000 0%, #000000c4 33%, #000000 90%) 0% 0%
		no-repeat padding-box;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}

.bottom-hero-shadow {
	width: 100vw;
	max-width: 100%;
	height: 30vh;
	background: transparent
		linear-gradient(180deg, #00000000 0%, #000000f0 73%, #000000 100%) 0% 0%
		no-repeat padding-box;
	bottom: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}
.upper-hero-shadow {
	width: 100vw;
	max-width: 100%;
	height: 18vh;
	background: transparent
		linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%) 0% 0%
		no-repeat padding-box;
	top: 0;
	left: 0;
	position: absolute;
	opacity: 0.73;
}

.hero-sub-headline {
	opacity: 0;
	font-size: 6.3rem;
	font-family: "Poppins", cursive;
	color: #f8802bc0;
	font-weight: 200;
	line-height: 0.4;
	letter-spacing: 2px;
	animation: fadeUp 0.5s forwards;
	animation-delay: 1s;
}
/*Make first letter twice bigger than the rest of the text*/
.hero-first-letter {
	font-family: "Poppins", cursive;
	text-transform: uppercase;
	font-size: 9.3rem;
	font-weight: 400;
}

.hero-headline {
	color: #fff;
	font-size: 2rem;
	font-family: "Poppins", sans-serif;
	letter-spacing: 0.5rem;
	/*   margin-right: -0.5rem;
 */
	animation: fadeUp 0.5s forwards;
	/* animation-delay: 1s; */
	margin: 0 -0.5rem 0 0;
	font-weight: 500;
	width: 40%;
	text-align: left;
	font: normal normal normal 32px/40px Poppins;
	letter-spacing: 2px;
	color: #ffffff;
	text-shadow: 0px 3px 13px #00000040;
}

.hero-bellow-text {
	color: #fff;
	font-size: 2rem;
	font-family: "Roboto", sans-serif;
	letter-spacing: 0.3rem;
	/*   margin-right: -0.5rem;
 */
	animation: scale 0.5s forwards;
	margin: 2vh -0.5rem 0 0;
	margin-bottom: 4vh;
}
.top-line {
	color: #fff;
	font-size: 1.3rem;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 7.4px;
	text-transform: uppercase;
	margin-bottom: 3rem;
}
.top-line span {
	color: #ff6b00;
}

.hero-separator {
	display: flex;
	align-items: center;
	justify-content: center;
}
.hero-line {
	width: 100%;
	max-width: 8.4rem;
	height: 0.25rem;
	background-color: #fff;
	position: relative;
	opacity: 0;
	animation: grow 2s forwards;
	animation-delay: 2s;
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
		both;
	animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation-delay: 0.7s;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(1000px);
		transform: translateY(1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.hero-line-right::before,
.hero-line-left::before {
	content: "";
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	border: 0.5rem solid transparent;
	opacity: 0;
	animation: grow 0.5s forwards;
	animation-delay: 1.2s;
}
.hero-line-right::before {
	border-right-color: #fff;
	right: 0;
}
.hero-line-left::before {
	border-left-color: #fff;
	left: 0;
}
.hero-asterisk {
	font-size: 3.2rem;
	color: #fa7312;
	margin: 0 1.6rem;
	opacity: 0;
	animation: spin 0.5s forwards;
	animation-delay: 0.7s;
}
.hero-single-animation {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	opacity: 0;
	animation: fadeDown 0.5s forwards;
	animation-delay: 1.5s;
	display: flex;
	flex-direction: column;
}

.hero-single-animation a {
	margin-bottom: 20px;
}

.hero-headline {
	margin-bottom: 4vh;
}
.hero-headline-description h5 {
	color: #fff;
	font-size: 1.4rem;
	font-weight: 100;
	text-transform: uppercase;
	margin-bottom: 1.2rem;
	letter-spacing: 3px;
	margin-right: -3px;
}
.btn {
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin-right: -2px;
}
.cta-btn {
	font-size: 1.1rem;
	background-color: #fff;
	padding: 0.9rem 1.8rem;
	color: var(--body-font-color);
	border-radius: 0.4rem;
	transition: background-color 0.5s;
}
.cta-btn:hover,
.cta-btn:focus,
.cta-btn:active {
	color: #fff;
	background-color: var(--fav-font-color);
}

.find-roof-button {
	font-size: 1.7rem;
}

.perks-container {
	height: 15vh;
	position: absolute;
	bottom: 0;
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	width: 90vw;
	align-self: center;
}

.perks-container :last-child {
	border: none;
}

@media screen and (max-width: 991px) {
	.container {
		padding-right: 30px;
		padding-left: 30px;
	}
}

@media screen and (max-width: 768px) {
	.home__hero-text-wrapper {
		padding-bottom: 65px;
	}

	.col {
		max-width: 100%;
		flex-basis: 100%;
	}
	.find-roof-button {
		font-size: 1.1rem;
	}
}

@media only screen and (max-width: 768px) {
	html,
	body {
		overflow-x: hidden;
	}
	.hero-sub-headline {
		font-size: 3rem;
	}
	.hero-bellow-text {
		font-size: 1.5rem;
	}
	.hero-first-letter {
		font-size: 3.8rem;
	}
	.hero-headline {
		font-size: 2.5rem;
		margin-top: 0.4rem;
		width: 100%;
		text-align: center;
	}
	.hero-line {
		max-width: 5.5rem;
		height: 0.15rem;
	}
	.hero-asterisk {
		font-size: 1rem;
	}
	.hero-headline-description {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hero-headline-description h5 {
		font-size: 1rem;
	}
	.hero-container .btn {
		width: 100%;
	}
	.hero-container {
		width: 80%;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.perks-container {
		/* display: none; */
	}
	.perk-item h3 {
		font-size: 0.8rem;
	}
	.fa-lg {
		font-size: 1.6em !important;
	}
}

@media only screen and (max-width: 550px) {
	.perks-container {
		display: none;
	}
}

@media only screen and (max-width: 400px) {
	.hero-headline {
		font-size: 1.6rem;
	}
	.map-with-controls-header h3 {
		font-size: 1.1rem;
	}
}

/*Animations*/
@keyframes fadeUp {
	0% {
		transform: translateY(4rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0s);
	}
}

@keyframes scale {
	0% {
		transform: scale(2);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes grow {
	0% {
		width: 0;
	}
	100% {
		opacity: 1;
		width: 100%;
	}
}

@keyframes fadeDown {
	0% {
		transform: translateY(-1rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0s);
	}
}
@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		opacity: 1;
		transform: rotate(-360deg);
	}
}
@keyframes up {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(-1rem);
	}
}
