.profile-container .description {
	width: 65%;
	margin: 0.5rem auto;
	padding: 1rem 0;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: -5vh;
	z-index: 5;
}

.profile-container .description h3 {
	margin-bottom: 0.5rem;
	border-bottom: 1px solid #cccccc;
	padding: 3% 4%;
	padding-left: 0;
	margin: 0 5%;
	font-size: 1.6rem;
	font-weight: 700;
}

.profile-container .description p {
	margin: 3% 5%;
	font-size: 1.2rem;
	letter-spacing: 0.04rem;
	font-weight: 400;
}

@media only screen and (max-width: 768px) {
	.profile-container .description {
		width: 90%;
	}
}
