.form-success-container {
	width: 30%;
	margin: 0 auto;
	border-radius: 10px;
	position: relative;
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.75) 0%,
		rgba(204, 136, 11, 0.8) 100%
			/*     rgba(17, 68, 25, 0.686) 0%,
    rgba(17, 102, 28, 0.604) 100% */
	);
	font-family: "Poppins";
}

.form-success-container h2 {
	padding: 0 5%;
	padding-top: 0;
}

.form-success-container h4 {
	padding: 5% 3%;
	margin: 3%;
	padding-top: 0;
	font-size: 1.4rem;
}

.upper-form-success {
	padding: 8%;
	padding-top: 12%;
}

.down-form-success {
}

.form-success-container i {
	font-size: 8rem;
	margin: 5%;
	color: #ffffff;
}
