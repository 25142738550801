.estimation-results {
	padding: 2%;
	padding-top: 0;
}

[title~="Stop"] {
	display: none !important;
}
/* 
[aria-label~="Stop drawing"] {
	display: none !important;
} */

.estimation-results-border {
	/* border: 2px solid rgba(255, 137, 58, 0.646);
	border-radius: 0 0px 20px 0;
	border-top: 0; */
	margin-top: 4%;
}
.measure-tool-svg-overlay .segment-text .segment-measure-text:first-child {
	user-select: none;
}
.bill-unit {
	background-color: #ffce3b;
	/* margin: -1px 0; */
	padding: 3% 0;
}

/* .gmnoprint {
	display: none;
} */

.output-field-bill {
	width: 90% !important;
}

.bill-output-label {
	display: none;
}

/* Chrome,
Safari,
Edge,
Opera input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

Firefox
input[type="number"] {
	-moz-appearance: textfield;
}
*/
.map-with-controls-container {
	display: grid;
	grid-template-columns: 2fr 5fr;
	padding-bottom: 0;
	margin-bottom: 0px;
	/* padding-top: 5%; */
}

.map-with-controls-container-simplified {
	grid-template-columns: 1fr;
}

.step-container-main {
	display: grid;
	grid-template-columns: 2fr 5fr;
}

.step-explenation {
	display: flex;
	align-items: center;
	padding: 1% 4%;
}

.step-explenation h3 {
	font: normal normal 300 18px/20px Poppins;
	font-size: 1.3rem;
}

.map-with-controls-header {
	text-align: center;
	padding: 6vh 20vw;
	position: relative;
}

.map-with-controls-header h1 {
	font-size: 3rem;
	font-weight: 700;
}

.map-with-controls-header h3 {
	font-size: 1.4rem;
	font-weight: 300;
	letter-spacing: 0.05rem;
}

.orangeletters {
	color: #ff6b00;
	font-weight: 700;
}

.additional-info {
	justify-self: right;
	font-size: 1.3rem;
	cursor: pointer;
	padding-top: 2%;
}

.step-grid {
	display: grid;
	grid-template-columns: 5fr 1fr;
}

.add-project-container {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.please-log-in-h3 {
	padding: 0.5%;
	color: #2e2e2e;
}

.please-log-in-h3 a {
	color: #ff6b00;
	cursor: pointer;
	text-align: center;
}

.controls-container {
	/* padding-left: 5%; */
	/* border: 2px solid rgba(255, 137, 58, 0.646); */
	border-radius: 0 20px 20px 0;
	margin-left: -2px;
}

.controls-container .upper-border {
	width: 100%;
	height: 40px;
	background: transparent linear-gradient(180deg, #000000 0%, #ffffff 100%) 0%
		0% no-repeat padding-box;

	mix-blend-mode: multiply;
	opacity: 0.07;
	position: absolute;
}

.controls-container .upper-border-small {
	width: 100%;
	height: 5px;
	background: transparent linear-gradient(180deg, #000000 0%, #ffffff 100%) 0%
		0% no-repeat padding-box;
	mix-blend-mode: multiply;
	opacity: 0.04;
	position: absolute;
}

.controls-container .controls-container-button {
	margin-top: 2%;
}

.controls-container h2 {
	padding-top: 2%;
	padding-bottom: 4%;
}

/* .triangle-bottom {
	position: absolute;
	margin-left: -18.5px;
	bottom: -19px;
	z-index: 99;
	display: inline-block;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 20px 0 20px;
	border-color: #ffffff transparent transparent transparent;
	line-height: 0;
	_border-color: #ffffff #000000 #000000 #000000;
	_filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
} */

.estimate-profit {
	display: flex;
	align-content: center;
	justify-content: center;
	width: 96%;
	margin: 0 auto;
	margin-top: 3%;
}
.gm-style {
	font: 400 14px "Poppins", Arial, sans-serif !important;
}

.measure-tool-svg-overlay .base .base-line:first-child {
	stroke: #ff0707 !important;
}

/* .measure-tool-svg-overlay .segment-text .segment-measure-text {
	text-shadow: 2px 2px 2px #ffffff, 0px 0px 3px #ffffff !important;
} */

.measure-tool-svg-overlay .segment-text .segment-measure-text:first-child {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-circle .head-circle:first-child {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-circle :nth-child(2) {
	stroke: #ff0707 !important;
}

.measure-tool-svg-overlay .node-measure-text {
	display: none;
}
.step-container {
	padding: 3% 0;
	padding-left: 5%;
	padding-right: 5%;
}

.step-container h2 {
	text-align: left;
	font: "Poppins";
	letter-spacing: 0px;
	color: #000000;
	font-weight: 700;
	padding-bottom: 1%;
	font-size: 1.2rem;
}

.step-container h3 {
	font: normal normal 300 18px/20px Poppins;
	font-size: 1.3rem;
}

.roofko-score {
	display: flex;
	flex-direction: row;
	margin-bottom: 3% !important;
	padding-left: 12% !important;
	padding-right: 12% !important;
}

.roofko-score-right {
	width: 30%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.roofko-score-left {
	width: 70%;
	display: flex;
	flex-direction: column;
	align-items: left;
	justify-content: center;
	text-align: left;
}

.roofko-score-percent-span {
	font-size: 3rem;
	color: rgb(255, 107, 0);
}

.roofko-score h2 {
	font-size: 2.5rem;
}
.roofko-score h3 {
	font-size: 1.5rem;
	width: 70%;
}

@media only screen and (max-width: 768px) {
	.map-with-controls-container {
		grid-template-columns: 1fr;
	}

	.map-container-width {
		height: 70vh !important;
	}
	.controls-container {
		border-radius: 0 0px 20px 20px;
	}
	.roofko-score {
		flex-direction: column;
	}
	.roofko-score-left {
		width: 100%;
	}
	.roofko-score h2 {
		text-align: center;
	}
	.roofko-score h3 {
		width: 100%;
		font-size: 1.1rem;
		text-align: justify;
	}
	.roofko-score-right {
		width: 100%;
	}

	.map-with-controls-header {
		text-align: center;
		padding: 8vh 8vw;
	}
	.map-with-controls-header h3 {
		font-size: 1.5rem;
	}
	.please-log-in-h3 {
		font-size: 1rem;
		text-align: center;
		margin: 0px 10px;
	}
	.step-explenation {
		display: none;
	}
	.step-container-main {
		grid-template-columns: 1fr;
	}
	.calculation-button h2 {
		font-size: 1.3em;
	}
}

@media only screen and (max-width: 394px) {
	.calculation-button h2 {
		font-size: 1.35em;
	}
}

@media only screen and (max-width: 276px) {
	.bill-unit {
		font-size: 1.3rem !important;
	}
	.unit {
		font-size: 1.3rem !important;
	}
}
/* 
@media only screen and (max-width: 480px) {
	.touch-circle {
		display: none;
	}
} */
