.output-data-row .output-fields {
	width: 80%;
	display: grid;
	grid-template-columns: 5fr 2fr;
	align-content: center;
	margin: 2% auto;
	box-shadow: 0px 0px 10px rgb(0 0 0 / 10%);
}
.output-data-row .output-field {
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.4rem 0 0 0.4rem;
	text-align: right;
	font-size: 1.5rem;
	padding: 2%;
	padding-right: 5%;
	border-right: none;
}

.output-data-row .unit {
	border: 1px solid #ced4da;
	border-radius: 0 0.4rem 0.4rem 0;
	font-size: 1.5rem;
	padding: 3% 15%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	text-align: center;
}

.output-data-row .output-label {
	display: block;
	font-size: 1.2rem;
	text-align: left;
	text-transform: uppercase;
	font-weight: 400;
	margin-right: 1rem;
	color: black;
}

.output-data-row .output-and-label {
	margin-top: 1%;
	width: 100%;
	padding: 0 1%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.nogrid {
	grid-template-columns: 1fr !important;
}

@media only screen and (max-width: 1000px) {
	.output-data-row .output-label {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 500px) {
	.output-data-row .output-field {
		font-size: 1rem;
		padding-right: 10%;
	}

	.output-data-row .unit {
		font-size: 1rem;
		padding: 3% 2%;
	}
}
