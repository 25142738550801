.choose-calculation-type-section {
}

.calculation-type-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3% 10%;
}

.choose-calculation {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
	grid-auto-rows: 1fr;
	grid-auto-flow: column;
	box-sizing: border-box;
	overflow: hidden;
}

.chosen {
	background-color: #e8e8e8;
}

.notchosen {
	background-color: white;
}

.calculation-button {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	cursor: pointer;
	padding: 10px 0;
	transition: 0.5s ease;
	-moz-transition: 0.5s ease;
	-webkit-transition: 0.5s ease;
	height: 100%;
}

.calculation-button:active {
	color: #ff6b00;
	box-shadow: 0 0 10px 10px rgba(116, 116, 116, 0.6);
}

.additional-calculation-explenation {
	padding: 4%;
	padding-bottom: 2%;
	text-align: center;
	font-weight: 300;
	letter-spacing: 0.03rem;
	font-size: 110%;
}

.calculation-button h2 {
	text-align: center;
}

.calculation-button-right {
	-webkit-clip-path: polygon(
		10% 0,
		100% 0,
		100% 0,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 32%
	);
	clip-path: polygon(
		10% 0,
		100% 0,
		100% 0,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 32%
	);
}

.calculation-button-left {
	-webkit-clip-path: polygon(
		0 0,
		90% 0,
		100% 32%,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 0
	);
	clip-path: polygon(
		0 0,
		90% 0,
		100% 32%,
		100% 100%,
		100% 100%,
		0 100%,
		0 100%,
		0 0
	);
}

@media only screen and (max-width: 500px) {
	.calculation-button p {
		display: none;
	}
}
