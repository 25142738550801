.slider-and-image-container {
	display: grid;
	grid-template-columns: 1fr 5fr;
	box-shadow: 2px 3px 3px #00000029;
	border: 1px solid #70707033;
	/* margin-right: 5%; */
	border-radius: 10px 10px 10px 10px;
	margin-top: 3%;
}

.slider-and-image-container p {
	text-align: center;
	font-weight: 700;
	color: white;
	margin-top: -10%;
	margin-left: 6%;
}

.roof-img-with-slope {
	background-color: #ff6b00;
	border-radius: 10px 0px 0px 10px;
	margin: -1px;
}

.MuiSlider-root {
	color: #ff6b00 !important;
}
.MuiSlider-marked {
	margin-bottom: 0 !important;
}

@media only screen and (max-width: 1000px) {
	.MuiSlider-markLabel {
		font-size: 1.2rem !important;
	}
}

@media only screen and (max-width: 500px) {
	.MuiSlider-markLabel {
		font-size: 0.9rem !important;
	}
	.MuiSlider-markLabel {
		top: 25px !important;
	}
}
