.m-btn {
	margin: 10px;
	padding: 15px 20px;
}
.control-buttons-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 90%;
	margin: 0 auto;
	margin-top: 5%;
	margin-bottom: 3%;
}

@media screen and (max-width: 1100px) {
	.control-buttons-container {
		grid-template-columns: 1fr;
	}
}

@media screen and (max-width: 768px) {
	.control-buttons-container {
		grid-template-columns: 1fr 1fr;
	}
}
