/* .badge-size {
	width: 100%;
	height: 100%;
}

.MuiAvatar-root {
	width: 100% !important;
	height: 100% !important;
}

*/

.MuiAvatar-root {
	box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: -1px -1px 16px 10px rgba(0, 0, 0, 0.3);
}

.MuiBadge-badge {
	height: 28% !important;
}

.MuiAvatar-circular {
	background: white;
}

.profile-up .MuiAvatar-root {
	width: 100%;
	height: 100%;
}

.profile-up .MuiBadge-root {
	width: 250px;
	height: 250px;
}
