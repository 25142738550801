.floating-menu {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	color: white;
	width: 25%;
	z-index: 999;
	align-items: center;
	justify-content: center;
	padding-left: 15%;
}
.no-display {
	display: none;
}

.floating-menu h6 {
	margin: 20px 15px;
	padding: 2px 0px;
	font-weight: 400 !important;
	letter-spacing: 0.3rem;
	white-space: nowrap;
	font-size: 1rem;
	cursor: pointer;
	transition: "transform 0.3s ease-in-out";
}

.floating-menu h6:hover {
	color: #f37021;
}
.special-border {
	border-left: 1px solid white;
	border-right: 1px solid white;
}

h6:nth-of-type(2) {
	border-left: 1px solid white;
	border-right: 1px solid white;
}
.floating-menu .active {
	border-bottom: solid 3px #ff7119;
	color: #f37021;
}

@media (max-width: 1200px) {
	.floating-menu {
		padding-left: 4%;
	}
}

@media (max-width: 960px) {
	.floating-menu {
		grid-template-columns: 1fr;
	}
}
