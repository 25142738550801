.suggestions {
	border: 1px solid rgb(62, 62, 62);
	padding-top: 10px;
	margin-top: -8px;
}

.city-suggestion {
	padding-top: 1.5%;
	padding-bottom: 1.5%;
}

.attribution-google {
	width: 40;
	height: auto;
	margin: 0 auto;
	float: right;
	background-color: white;
}
.suggestion-grid {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
