.project-wrapper {
	background: rgba(211, 211, 211, 0.384);
	background-position: center;
}

.project-container {
	margin: 0 auto;
	width: 100%;
	/* box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%); */
	border-radius: 10px;
	/* background: rgba(0, 0, 0, 0.089); */
	display: flex;
	flex-direction: column;
	padding-top: 100px;
}

.project-img {
	width: 100%;
	border-radius: 10px 10px 0 0;
}

.project-section-header {
	font-family: "Poppins";
	font-weight: 900;
	padding: 3% 0;
	border-bottom: solid 1px #cccccc;
	font-size: 1.4rem;
	letter-spacing: 0.05;
}

.white-section {
	background: white;
	border-radius: 10px;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	padding: 0 4%;
	padding-top: 1%;
	margin-bottom: 2% 0;
	padding-bottom: 2%;
	margin-bottom: 5%;
}

.project-img-container {
	margin-bottom: 2%;
	width: 20%;
}

.project-container .profile-right .detail {
	font-size: 1.4rem;
}

.project-container .profile-right .detail i {
	font-size: 1.7rem;
}

.project-overview-container {
	width: 100%;
	margin: 0 auto;
}

.available-project-overview-container {
	width: 100%;
	margin: 0 auto;
}

@media only screen and (max-width: 1000px) {
	.project-section-header {
		font-size: 1.7rem;
	}
}

@media only screen and (max-width: 768px) {
	.project-overview-container {
		width: 90%;
	}
}

@media only screen and (max-width: 768px) {
	.project-section-header {
		font-size: 1rem;
	}
}
