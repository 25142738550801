.project-card-container {
	width: 70%;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	/* height: 23vh; */
	margin: 6vh auto;
	display: grid;
	grid-template-columns: 2.1fr 3.8fr 0.1fr 0.6fr 1fr;
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	background: white;
	min-height: 220px;
}

.project-card-container img {
	border-radius: 15px;
	width: 100%;
	height: 110%;
	object-fit: cover;
	overflow: hidden;
	position: relative;
	top: -5%;
}

.main-image {
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.project-card-container .project-data {
	margin-left: 35px;
}

.project-card-container h2 {
	font-family: "Poppins", sans-serif;
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 1px;
	margin: 10px 0px;
	margin-bottom: 20px;
	padding: 5px;
	color: #f56900;
}

.project-card-container .project-rating-container {
	width: 80%;
}

.project-card-container .location-container {
	margin: 15px -10px;
}

.project-card-container .location-container .main-image {
	width: 40%;
}

.project-card-container .location-container h4 {
	font-weight: 500;
}

.project-card-container .control-logos {
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	justify-content: center;
}

.project-card-container .control-logos {
	grid-template-columns: 1fr;
}

.project-card-container .control-logos img {
	width: 23%;
	height: auto;
	border-radius: 0;
	cursor: pointer;
}

.project-card-container .status {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 500;
}

.project-card-container .project {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.project-card-container .project-data h4 {
	font-weight: 600;
	font-size: 15px;
}

.project-card-container .user-container {
	margin: auto 0;
}

.project-card-container .location-continer {
	display: grid;
	width: 70%;
	grid-template-columns: 1fr 4fr;
	margin-top: 10px;
}

.project-card-container .location-text {
	align-self: center;
}

.project-card-container .location-continer .main-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.project-card-container .contractor-data h3 {
	margin-bottom: 5px;
	margin-top: 5px;
	display: flex;
	align-items: center;
}

.contractor-data i {
	font-size: 2rem;
	margin-right: 1rem;
	cursor: pointer;
}
.flaticon-pin {
	color: #ff6b00;
}

.location-header-container {
	margin-top: 1rem !important;
}

@media only screen and (max-width: 768px) {
	.project-card-container {
		grid-template-columns: 2.5fr 3.8fr 0.1fr 0.1fr 1fr;
		width: 90%;
	}

	.project-card-container .control-logos img {
		width: 70%;
	}
}

@media only screen and (max-width: 500px) {
	.project-card-container {
		display: block;
		padding-bottom: 2rem;
	}
	.main-image {
		box-shadow: none;
	}

	.project-card-container .control-logos img {
		width: 13%;
	}
}
