@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700;800;900&display=swap");

* {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
}

body {
	margin: 0;
	padding: 0;
}

html,
body {
	max-width: 100%;
	/* position sticky for header does not work with overflow-hidden */
	/* overflow-x: hidden; */
}

@font-face {
	font-family: Inter;
	src: url("./Assets/fonts/Inter-Regular.ttf");
}

font-face {
	font-family: Roboto;
	src: url("./Assets/fonts/Roboto-Regular.ttf");
}

textarea {
	resize: none;
	width: 100%;
}

/* scale every text to 80% */

html {
	font-size: 80%;
}

.ReactModal__Overlay {
	z-index: 10;
}

.section-heading-font {
	font-family: Poppins, cursive;
	color: #f8802b;
	font-weight: 600;
	font-size: 2rem;
	margin: 2rem;
	text-transform: uppercase;
	text-align: left;
	margin-left: 0;
	letter-spacing: 0.02rem;
}

a {
	color: inherit;
}

html {
	scroll-behavior: smooth;
}

.other-pages {
	width: 90%;
}

@media only screen and (max-width: 500px) {
	.section-heading-font {
		font-size: 1.6rem;
		text-align: center;
	}
}
