.rating-company {
	color: #ff6b00;
	font-size: larger;
}

.give-review-container h4 {
	font-size: larger;
}

.explanation {
	display: grid;
	gap: 5%;
	margin-top: 5%;
	justify-content: center;
}

@media only screen and (max-width: 768px) {
	.explanation {
		grid-template-columns: 1fr;
		gap: 0%;
		margin-top: 0%;
	}
}
