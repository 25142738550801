.explenation-dialog-z-index {
	z-index: 100000000000000000;
}

.MuiTypography-body1 {
	font-weight: 600 !important;
}

.measure-explenation .MuiTypography-h6 {
	font-size: 1.8rem !important;
	text-align: center;
}

.alert_dialog_measure_explenation_title {
	text-align: center !important;
}

@media only screen and (max-width: 768px) {
	.explenation-dialog-z-index .MuiDialog-paper {
		margin: 6px !important;
	}
	.alert_dialog_measure_explenation_title {
		text-align: center !important;
		margin-bottom: 20px;
	}
	.MuiDialogContent-root {
		padding: 8px 10px !important;
	}
}
