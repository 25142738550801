.regulative-files-table .regulative-grid {
	display: grid;
	grid-template-columns: 0.7fr 3fr 0.4fr;
	padding: 20px 0px;
	border: #958c8c 1px solid;
	margin: 20px 0;
	align-items: center;
	justify-items: center;
}

.regulative-files-table .regulative-grid h3 {
	color: #858583;
	font-weight: 700;
}

.regulative-data {
	justify-self: self-start;
}

.regulative-border {
	border-bottom: solid 3px #f37021;
	margin-bottom: 10px;
}

.regulative-fileicon-container {
	width: 30%;
	margin: auto;
}
