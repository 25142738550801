.output-fields {
	width: 100%;
	display: grid;
	grid-template-columns: 5fr 1.5fr;
	align-content: center;
	margin: 2% auto;
}
.output-field {
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: 0.4rem 0 0 0.4rem;
	text-align: right;
	font-size: 1.5rem;
	padding: 3%;
	padding-right: 5%;
	border-right: none;
}

.input-field-important {
	text-align: left;
	border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
	border: 1px solid #ced4da;
}

.unit {
	border: 1px solid #ced4da;
	border-radius: 0 0.4rem 0.4rem 0;
	font-size: 1.5rem;
	padding: 3% 30%;
	background-color: #ffc107c9;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -5px;
	color: white;
}

.otput-label {
	display: block;
	font-size: 1.2rem;
	font-weight: 300;
}

.output-and-label {
	margin-top: 3%;
	width: 100%;
	padding-bottom: 0;
}

@media screen and (max-width: 1200px) {
	.map-with-controls-container .output-field {
		font-size: 1.3rem;
	}
}

@media screen and (max-width: 1000px) {
	.map-with-controls-container .output-field {
		font-size: 1.1rem;
	}
}

@media screen and (max-width: 900px) {
	.map-with-controls-container .output-field {
		font-size: 1rem;
	}
}

@media screen and (max-width: 768px) {
	.map-with-controls-container .output-field {
		font-size: 1.3rem;
	}
}
