.subscribe-section {
	width: 100%;
	/* height: 50vh; */
	background: linear-gradient(
			180deg,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 107, 0, 0.9) 85%,
			rgba(255, 106, 0, 0.9) 100%
		),
		url("../../../Assets/Images/SubscribeBackground.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: bottom;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.subscribe-section-inner {
	width: 25%;
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	background: #00000020 0% 0% no-repeat padding-box;
	padding: 3% 3%;
	margin: 3% auto;
	border-radius: 7px;
	padding-top: 3%;
}

#submit-subheading {
	font-size: 1.2em;
	font-weight: lighter;
}

#subscribe-email {
	width: 100% !important;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	position: relative;
	z-index: 1000;
	margin: 10px 0;
}

#ask-question {
	width: 97% !important;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	top: 6px;
	z-index: 1000;
	padding-left: 10px !important;
	padding-top: 20px !important;
	text-align: center !important;
}

.submit-question {
	margin-top: 10px !important;
}

.form-inputs-subscribe {
	width: 100% !important;
}

.button-subscribe {
	width: 100%;
	padding: 10px 26px;
	font-size: 15px;
	box-shadow: 0px 3px 6px #00000029;
	border-radius: 5px;
	padding-top: 12px;
}

.form-input-subscribe {
	padding-left: 0 !important;
	height: 50px;
	box-shadow: 0px 3px 6px #00000029;
}

input {
	text-align: center;
}

.submit-heading {
	text-align: center;
	color: white;
	text-shadow: 0px 3px 7px #00000029;
	font-size: 1.5em;
	margin-bottom: 6%;
}

.fun-facts-heading {
	font-size: 3rem;
	font-weight: 700;
	color: white;
	margin-top: 4%;
}

@media only screen and (max-width: 1200px) {
	.subscribe-section-inner {
		width: 33%;
	}
}

@media only screen and (max-width: 750px) {
	.subscribe-section-inner {
		width: 50%;
	}
}
@media only screen and (max-width: 500px) {
	.subscribe-section-inner {
		width: 80%;
	}
}

@media only screen and (max-width: 400px) {
	.subscribe-section-inner {
		width: 85%;
	}
}
