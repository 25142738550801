.form-container-make-an-offer-form {
	grid-template-columns: 1fr;
	width: 35vw;
}

.only-for-make-an-offer {
	grid-template-columns: 1fr;
	width: 60vw;
	height: 650px;
}

.form-container-make-an-offer-form h1 {
	font-size: 1.6rem;
	text-align: left;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 1rem;
}

.form-container-make-an-offer-form .checkboxes {
	align-self: flex-start;
	margin: 1% auto;
	width: 90%;
	color: white;
}

.form-container-make-an-offer-form textarea {
	padding-top: 10px;
	height: 130px;
}

.form-container-make-an-offer-form h2 {
	width: 80%;
	color: white;
	margin-top: 2%;
}

.form-container-make-an-offer-form .form-inputs {
	margin: 1% auto;
	width: 90%;
}

.form-container-make-an-offer-form .make-an-offer-button {
	margin: 2%;
}

.form-container-make-an-offer-form fieldset {
	margin: 3%;
	border-radius: 2%;
}

.form-container-make-an-offer-form legend {
	padding: 2%;
	color: white;
}

.close-button {
	color: white;
	position: absolute;
	top: 0;
	right: 0;
	margin-top: 5%;
	cursor: pointer;
}

.only-for-make-an-offer .close-button {
	margin-top: 3%;
}

.form-inputs-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
}

.last-form-input {
	margin-bottom: 20px !important;
}

@media only screen and (max-width: 768px) {
	.form-container-make-an-offer-form {
		width: 80vw;
	}
}

@media only screen and (max-width: 500px) {
	.form-container-make-an-offer-form {
		width: 90vw;
	}

	.ReactModal__Content {
		border-radius: 0px !important;
	}

	.form-content-right {
		border-radius: 0px !important;
	}

	.form-inputs-grid {
		grid-template-columns: 1fr;
	}
}
