.profile-wrapper .profile-container .knowledgemenu {
	grid-template-columns: 1.3fr 5fr;
	width: 90%;
}

.knowledgemenu h1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
	font-size: 2.5rem;
}

.knowledgemenu .profile-container {
	width: 90%;
}

.knowledgemenu ul {
	list-style-type: square;
	list-style-position: inside;
}

.knowledgemenu ul li::before {
	color: "#F37021";
}

.knowledgemenu {
	background-color: white;
}

.knowledgemenu .project-data-margin-top {
	margin-top: -12vh;
}

.knowledgemenu .financing-page .project-data-margin-top {
	margin-top: -20vh;
}

.financing-page li {
	font-size: 1.2rem;
}

.financing-page li::marker {
	color: #000;
}

.knowledgemenu .white-section {
	padding: 6% 9%;
}

.knowledgemenu .financing-page .white-section p {
	font-size: 1.2rem;
	text-align: justify;
	font-weight: 400;
}

.knowledgemenu .financing-page .white-section a p {
	color: blue;
}

.financing-page .city-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.knowledgemenu .white-section p {
	font-size: 1.6rem;
	text-align: justify;
	font-weight: 400;
}

.knowledgemenu .white-section h3 {
	margin-top: 5%;
	margin-bottom: 3%;
	color: #f37021;
	font-size: 1.9rem;
}

.knowledgemenu .white-section h3::before {
	content: "•";
	display: inline-block;
	margin-right: 15px;
	color: #f37021;
}

body .profile-wrapper .financing-page {
	grid-template-columns: 1fr;
}

.knowledgemenu .MuiTypography-h5 {
	font-weight: 700;
}
.knowledgemenu .white-section h1 {
	margin-bottom: 4rem;
}
.indend {
	margin-left: 4%;
}
.MuiTableCell-head {
	font-weight: bold !important;
}
.MuiTableCell-root {
	font-size: 1.4rem !important;
}

@media only screen and (max-width: 768px) {
	.knowledgemenu .project-data-margin-top {
		margin-top: 0vh;
	}
	.MuiTableCell-root {
		padding: 8px !important;
	}
	.knowledgemenu .white-section p {
		font-size: 1.1rem;
	}
	.MuiTableCell-root {
		font-size: 1.1rem !important;
	}
	.knowledgemenu .white-section h3 {
		font-size: 1.3rem;
	}
	.knowledgemenu h1 {
		font-size: 2rem;
	}

	.knowledgemenu .white-section h1 {
		margin-bottom: 2.5rem;
	}
}

@media only screen and (max-width: 400px) {
	.MuiTableCell-root {
		padding: 3px !important;
	}
	.knowledgemenu .white-section p {
		font-size: 0.8rem;
	}

	.financing-page li {
		font-size: 1rem;
	}

	.financing-page .city-grid {
		grid-template-columns: 1fr 1fr;
	}

	.knowledgemenu .financing-page .white-section p {
		font-size: 1rem;
	}
	.MuiTableCell-root {
		font-size: 0.8rem !important;
	}
	.knowledgemenu .white-section h3 {
		font-size: 1.1rem;
	}
}
