.project-data-grid {
	display: grid;
	grid-template-columns: 5fr 5fr;
	grid-gap: 2%;
}

.project-data-container {
	margin: 0 auto;
}

.project-data-margin-top {
	margin-top: -5vh;
}

.project-data-grid h3 {
	font-weight: 500;
	font-size: 1.4rem;
	text-align: center;
	margin: 2%;
	letter-spacing: 0.1rem;
	margin: 4% 0;
	padding: 4% 0;
}

@media only screen and (max-width: 1000px) {
	.project-data-margin-top {
		margin-top: 0;
	}
}

@media only screen and (max-width: 768px) {
	.project-data-grid {
		grid-template-columns: 1fr;
	}
	.output-data-row .output-label {
		font-size: 1.6rem;
	}
}

@media only screen and (max-width: 500px) {
	.project-data-grid {
		grid-template-columns: 1fr;
	}

	.output-data-row .output-label {
		font-size: 0.8rem !important;
	}
	.cashflow-project-data {
		display: none;
	}
}
