/* .scale-project-container {
	display: grid;
	margin: 4% 0;
	border-top: 1px solid gray;
	padding: 4% 0;
	border-bottom: 1px solid gray;
} */

.scale-slider-container {
	display: flex;
	align-items: center;
	margin-bottom: 3%;
	margin-top: 3%;
}

.scale-slider-container:first-child {
	width: 90% !important;
	padding-right: 5%;
}

.scale-button {
	width: 70%;
	margin-right: 0;
}

.scale-button .makeStyles-button-13 {
	font-size: 1rem !important;
}

.scale-button .flaticon-scale {
	font-size: 1.4rem !important;
}

.scale-project-heading {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	margin-left: 10%;
}

@media only screen and (max-width: 1000px) {
	.scale-button .makeStyles-button-13 {
		font-size: 1.3rem !important;
	}
}

@media only screen and (max-width: 500px) {
	.scale-project-container {
		grid-template-columns: 1fr;
	}

	.scale-button .makeStyles-button-13 {
		font-size: 0.7rem !important;
	}

	.scale-button {
		margin: 0 auto;
		align-items: center;
		justify-content: center;
	}
	.scale-button:first-child {
		font-size: 0.7em;
	}

	.makeStyles-root-52 {
		width: 85% !important;
	}
}
