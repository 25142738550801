.knowledgemenu .profile-up {
	margin-top: 4vh;
	align-items: start;
}

.knowledgemenu .profile-down {
	margin-top: 3%;
}

.title-container-knowledgemenu {
	display: grid;
	grid-template-columns: 1fr 5fr;
	margin: 3%;
}

.Mui-expanded {
	margin: 0 !important;
}

.profile-up h2 {
	margin-top: 1.2rem;
}

.profile-up h3 {
	margin-left: 1rem;
}

.profile-up {
	padding-left: 1rem;
}

.knowledge-menu .MuiAccordion-rounded::before {
	display: none;
	margin: 0;
}

.knowledge-menu .MuiAccordion-root:last-of-type {
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.click-element {
	cursor: pointer;
}

.knowledge-menu .MuiAccordionSummary-content {
	margin: 0;
	padding: 16px 0;
}

.knowledge-menu .MuiAccordion-root {
	border-bottom: solid 1px #958c8c;
}

li::marker {
	color: #f37021;
}
