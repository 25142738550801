.offer-details-modal-data .output-data-row .output-label {
	color: white;
	margin-left: 7%;
}

.offer-details-modal-data .output-data-row .output-field {
	font-size: 1.2rem;
	padding-right: 7%;
}

.offer-details-modal-data .output-data-row .unit {
	font-size: 1.2rem;
}

.offer-details-modal-data {
	width: 80vw !important;
}

.offer-details-modal-data .output-data-row .output-and-label {
	margin: 4% 0;
}

.offer-details-modal-data h2 {
	width: 90%;
}

.offer-details-modal-data textarea {
	height: 240px !important;
	background: #ffffff45;
	color: white;
}

.offer-details-modal-data .unit {
	margin-left: 0px;
}

.offer-details-modal-data .unit {
	margin-left: 0px;
}

.offer-details-modal-data .output-data-row .unit {
	margin-left: 0px;
}

.offer-details-modal-data .output-data-row .output-and-label {
	grid-template-columns: 1fr 1.3fr;
}
.offer-details-modal-data .output-data-row .output-fields {
	width: 90%;
}
