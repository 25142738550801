.offer-card-container {
	width: 100%;
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
	margin: 6vh auto;
	display: grid;
	grid-template-columns: 1.5fr 3.8fr 0.1fr 0.1fr 2fr;
	border-radius: 15px;
	font-family: "Raleway", sans-serif;
	background: white;
	min-height: 220px;
}

.offer-card-container img {
	border-radius: 15px;
	width: 100%;
	height: 110%;
	object-fit: cover;
	overflow: hidden;
	position: relative;
	top: -5%;
}

.offer-card-container .main-image {
	box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.offer-card-container .project-data {
	margin-left: 35px;
	flex-direction: column;
	display: flex;
}

.offer-card-container h2 {
	font-family: "Poppins", sans-serif;
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: 1px;
	margin: 10px 0px;
	margin-bottom: 20px;
	padding: 5px;
}

.offer-card-container .project-rating-container {
	width: 80%;
}

.offer-card-container .location-container {
	margin: 15px -10px;
}

.offer-card-container .location-container .main-image {
	width: 40%;
}

.offer-card-container .location-container h4 {
	font-weight: 500;
}

.offer-card-container .control-logos {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10% 0;
}

.offer-card-container .control-logos img {
	width: 60%;
	height: auto;
	border-radius: 0;
	cursor: pointer;
}

.offer-card-container .status {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 500;
}

.offer-card-container .project {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.offer-card-container .project-data h4 {
	font-weight: 600;
	font-size: 15px;
}

.offer-card-container .user-container {
	margin: auto 0;
}

.offer-card-container .location-continer {
	display: grid;
	width: 70%;
	grid-template-columns: 1fr 4fr;
	margin-top: 10px;
}

.offer-card-container .location-text {
	align-self: center;
}

.offer-card-container .location-continer .main-image {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.offer-card-container .contractor-data {
	margin: auto 2%;
	margin-top: 5%;
	margin-bottom: 14%;
}

.offer-card-container .contractor-data h3 {
	margin-bottom: 7px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 1.3em;
}

.offer-card-container .contractor-data i {
	font-size: 2rem;
	margin-right: 1rem;
	cursor: pointer;
}

.offer-card-container p {
	font-size: 1rem;
	color: rgb(0, 0, 0);
	margin-top: 5px;
}

.view-offer-details {
	cursor: pointer;
}

@media only screen and (max-width: 768px) {
	.offer-card-container {
		grid-template-columns: 1fr;
		width: 100%;
		font-size: 1.5rem;
	}
	.offer-card-container .control-logos {
		flex-direction: row;
		margin: 5% 0;
	}

	.offer-card-container .control-logos > * {
		margin: 3%;
	}

	.offer-card-container h2 {
		margin-top: 4rem;
		font-size: 2.4rem;
	}

	.offer-card-container .project-data h4 {
		font-weight: 600;
		font-size: 20px;
	}

	.offer-card-container p {
		font-size: 1.2rem;
	}
	@media only screen and (max-width: 500px) {
		.offer-card-container .contractor-data h3 {
			font-size: 1.3rem;
		}
		.offer-card-container h2 {
			font-size: 2rem;
		}
		.offer-card-container .project-data h4 {
			font-size: 17px;
		}
	}
}
