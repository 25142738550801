.MuiDialog-paperWidthSm {
	max-width: 720px !important;
}

.MuiCardMedia-root {
	background-size: contain !important;
}

@media screen and (max-width: 500px) {
	.explanation {
		grid-template-columns: 1fr;
	}
}
