.container-fun-facts {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	width: 100%;
	margin: 20px 0 10px;
}
.container-fun-facts .card {
	cursor: pointer;
	width: 300px;
	margin: 10px;
	background-color: black;
	box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
	border-radius: 20px;
}
.container-fun-facts .card:hover .card__caption {
	top: 50%;
	transform: translateY(-50%);
}
.container-fun-facts .card:hover .card__image {
	transform: translateY(-10px);
}
.container-fun-facts .card:hover .card__thumb::after {
	top: 0;
}
.container-fun-facts .card:hover .card__snippet {
	margin: 20px 0;
}
.container-fun-facts .card__thumb {
	position: relative;
	max-height: 400px;
	overflow: hidden;
	border-radius: 20px;
}

.container-fun-facts .card__thumb img {
	opacity: 0.9;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__thumb {
		max-height: 350px;
	}
}
.container-fun-facts .card__thumb::after {
	position: absolute;
	top: 0;
	display: block;
	content: "";
	width: 100%;
	height: 100%;
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.5) 40%,
		rgba(255, 255, 255, 0) 100%
	);
	transition: 0.3s;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__thumb::after {
		top: calc(100% - 140px);
	}
}
.container-fun-facts .container-fun-facts .card__image {
	transition: 0.5s ease-in-out;
}
.container-fun-facts .card__caption {
	position: absolute;
	top: 50%;
	z-index: 1;
	padding: 0 20px;
	color: white;
	transform: translateY(-50%);
	text-align: center;
	transition: 0.3s;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__caption {
		top: calc(100% - 110px);
		transform: unset;
	}
}
.container-fun-facts .card__title {
	display: -webkit-box;
	max-height: 85px;
	overflow: hidden;
	font-family: "Poppins", serif;
	font-size: 23px;
	line-height: 28px;
	text-shadow: 0px 1px 5px black;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.container-fun-facts .card__snippet {
	display: -webkit-box;
	max-height: 150px;
	margin: 20px 0;
	overflow: hidden;
	font-family: "Poppins", sans-serif;
	font-size: 16px;
	line-height: 20px;
	text-overflow: ellipsis;
	transition: 0.5s ease-in-out;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
}
@media (min-width: 1024px) {
	.container-fun-facts .card__snippet {
		margin: 60px 0;
	}
}
.container-fun-facts .card__button {
	display: inline-block;
	padding: 10px 20px;
	color: white;
	border: 1px solid white;
	font-family: "Roboto", sans-serif;
	font-size: 12px;
	text-transform: uppercase;
	text-decoration: none;
	transition: 0.3s;
}
.container-fun-facts .card__button:hover {
	color: black;
	background-color: white;
}
.container-fun-facts .disclaimer {
	position: fixed;
	bottom: 0;
	left: 50%;
	z-index: 2;
	box-sizing: border-box;
	width: 100%;
	padding: 20px 10px;
	background-color: white;
	transform: translateX(-50%);
	font-family: "Roboto", sans-serif;
	font-size: 14px;
	text-align: center;
}
.container-fun-facts .disclaimer__link {
	color: #755d87;
	text-decoration: none;
}
