.make-an-offer-conatiner {
	display: grid;
	justify-content: center;
	align-items: center;
	margin: 3%;
	position: relative;
}

.project-card-for-contractor-make-an-offer {
	grid-template-columns: 2fr 3.3fr 0.1fr 0.1fr 2fr;
}

.project-card-container-contractor-card {
	min-height: 250px;
}
.make-an-offer-conatiner h4 {
	font-weight: 500;
}
.bold-offer-data {
	font-weight: 600;
}

.offer-data {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: 3%;
}

@media only screen and (max-width: 500px) {
	.offer-data {
		position: relative;
		text-align: center;
	}
}
