.hero-not-found .hero-headline {
	width: 100%;
	font-size: 20rem;
}

.hero-not-found h3 {
	color: white;
	font-size: 2rem;
	margin-left: 2rem;
}

.hero-not-found h2 {
	color: white;
	font-size: 3rem;
	margin-left: 2rem;
}

.hero-not-found .hero-container {
	margin-top: 0;
}

.first-row-container {
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
	width: 70%;
	margin-bottom: 10vh;
}

.hero-not-found {
	background-image: url(../../../Assets/Images/broken-solar.svg);
	background-size: cover;
	background-repeat: no-repeat;
}

#under-constraction {
	background-image: url(../../../Assets/Images/UnderConstruction.jpg);
	background-size: cover;
	background-repeat: no-repeat;
}
