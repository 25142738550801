.slider-and-image-container-orientation .MuiSlider-track {
	width: 0% !important;
}

.slider-and-image-container-orientation {
	display: flex !important;
	flex-direction: column;
	border: none;
	box-shadow: none;
}

.slider-and-image-container-orientation .rotatingRoof {
	display: flex;
	align-items: center;
	justify-content: center;
}

.slider-and-image-container-orientation .roof-img-with-slope {
	background-color: transparent !important;
}
#turn {
	transition: all 0.3s linear;
}
