.files-container {
	margin: 3vh 0;
}

.files-container .MuiAvatar-root {
	border-radius: 13%;
}

.files-container .MuiListItem-container::marker {
	display: none;
	color: transparent;
}

.files-container .MuiAvatar-root {
	height: 47px;
}
