.navbar {
	background: #1c223700;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 10; /*   prethodni (sa bc bojom nezavistan od hero sekcije) */
	/*   margin-bottom: 7rem;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  z-index: 999; */
	/*Novi (Transparentni, predstavlja dio hero sekcije)*/
}

.navbar-absolute {
	position: absolute;
	width: 100vw;
	max-width: 100%;
	top: 0;
	left: 0;
	background: transparent
		linear-gradient(359deg, #00000000 0%, #00000000 21%, #0000006e 90%) 0% 0%
		no-repeat padding-box;
	/* height: 20vh; */
}

.navbar-container {
	display: flex;
	justify-content: space-between;
	height: 80px;
	z-index: 1;
	width: 100%;
	max-width: 1500px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 50px;
	padding-left: 50px;
}

.navbar-container .ReactFlagsSelect-module_selectOptionValue__vS99- {
	flex-direction: column;
}

.navbar-logo {
	margin-top: 0.5%;
}

.navbar-logo-div {
	background: url("../../Assets/Images/Roofko_white.svg");
	width: 14rem;
	height: 5.2rem;

	/* height: 75%; */
	background-position: center;
	z-index: 2000;
	background-size: contain;
	background-repeat: no-repeat;
}

.footer-logo .footer-logo-div {
	background: url("../../Assets/Images/solar-logo-footer.svg");
}

.navbar-icon {
	margin-right: 0.5rem;
}

.nav-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	text-align: center;
}

.nav-item {
	height: 80px;
	border-bottom: 2px solid transparent;
}

.nav-item:hover {
	border-bottom: 2px solid #ff6b00;
}

.nav-links {
	color: #fff;
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	outline: none;
}

.fa-bars {
	color: #fff;
}

.menu-icon {
	display: none;
}

.visibility-hidden {
	visibility: hidden;
}

.navbar-user-card-conatiner {
	z-index: 300;
	position: fixed;
	right: -10%;
	top: 100px;
	padding: 0.4% !important;
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.489) 0%,
		rgba(204, 136, 11, 0.441) 100%
			/* rgba(17, 68, 25, 0.686) 0%, rgba(17, 102, 28, 0.604) 100% */
	);
}

.navbar-user-card-conatiner .profile-details {
	grid-template-columns: 1fr;
}

.navbar-user-card-conatiner .flex-display {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	z-index: 5;
}

.navbar-user-card-conatiner .profile-img {
	width: 16%;
	height: 100%;
}

.navbar-user-card-conatiner h4 {
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 600;
}

.navbar-user-card-conatiner .user-card-container {
	padding: 1%;
}

.nav-avatar-container {
	width: 80%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.nav-avatar-container h3 {
	margin-left: 1rem;
}

.mobile-avatar-container {
	width: 70%;
	display: flex;
	flex-direction: row;
}

@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.navbar-logo-div {
		width: 13rem;
		height: 3rem;
	}

	.nav-menu {
		display: flex;
		flex-direction: column;
		justify-content: start;
		width: 100%;
		height: 90vh;
		position: absolute;
		/* top: 80px; */
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		padding-top: 12vh !important;
	}

	.nav-menu.active {
		background: #1f253bea;
		padding-top: 0rem;
		transition: all 0.6s ease;
		z-index: 1;
		left: 0;
		opacity: 1;
		width: 100vw;
		max-width: 100%;
		height: calc(var(--vh, 1vh) * 100);
	}

	.nav-links {
		text-align: center;
		padding: 2rem;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		color: #ff6b00;
		transform: scale(1.2);
		transition: all 0.3s ease;
	}

	.nav-item:hover {
		border: none;
	}

	.nav-item {
		width: 100%;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		padding: 10px;
		margin-left: 30px;
		/* margin-top: 2%; */
		z-index: 3;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 12px;
		right: 12px;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		z-index: 3;
	}

	.fa-times {
		color: #fff;
		font-size: 2rem;
	}

	.nav-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 80px;
	}
}
