.hero-page {
	height: 27vh;
	width: 100vw;
	max-width: 100%;
	background: url(../../../Assets/Images/HeroBackground.jpeg) center no-repeat;
	background-size: cover;
}

.hero-page-shadow {
	background: transparent
		linear-gradient(270deg, #000000 0%, #000000c4 33%, #000000 77%) 0% 0%
		no-repeat padding-box;
	opacity: 0.4;
	width: 100%;
	height: 27vh;
}
