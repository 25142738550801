@import url("https://fonts.googleapis.com/css2?family=Bubblegum+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap");

.form-wrapper {
	background: linear-gradient(rgba(87, 87, 87, 0.564), rgba(0, 0, 0, 0.386)),
		url("../../Assets/Images/HeroBackground.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 80px;
	height: calc(var(--vh, 1vh) * 100);
	box-sizing: border-box;
	padding-top: 13vh;
}
.register-background {
	background: linear-gradient(rgba(87, 87, 87, 0.564), rgba(0, 0, 0, 0.386)),
		url("../../Assets/Images/HeroBackground.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: calc(var(--vh, 1vh) * 100);
	padding: 80px;
	padding-top: 135px;
	box-sizing: border-box;
	padding-top: 13vh;
}

.form-login-bck {
	background: url("../../Assets/Images/HeroBackground.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding: 80px;
}

.form-container {
	margin: 0 auto;
	width: 65vw;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.3), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
	position: relative;
	opacity: 0.85;
	border-radius: 10px;
	height: 600px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	background: rgba(0, 0, 0, 0.089);
	backdrop-filter: blur(2px);
}

.close-btn {
	position: absolute;
	top: 2%;
	right: 3%;
	font-size: 1.5rem;
	z-index: 1;
	color: #fff;
	cursor: pointer;
}

.form-content-left {
	background: linear-gradient(
		90deg,
		rgba(196, 121, 22, 0.489) 0%,
		rgba(204, 136, 11, 0.441) 100%
			/*     rgba(17, 68, 25, 0.686) 0%,
    rgba(17, 102, 28, 0.604) 100% */
	);
	border-radius: 10px 0 0 10px;
	position: relative;
}

.form-img {
	width: 80%;
	height: 80%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-img-2 {
	width: 60%;
	height: 60%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.form-success {
	text-align: center;
	font-size: 24px;
	margin-top: 80px;
	color: #fff;
}

.form-content-right {
	border-radius: 0 10px 10px 0;
	position: relative;
	background: linear-gradient(90deg, rgb(40, 40, 40) 0%, rgb(17, 17, 17) 100%);
	overflow: auto;
}

.form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-family: Arial, Helvetica, sans-serif;
}

.form h1 {
	font-size: 3.2rem;
	text-align: center;
	width: 80%;
	color: #fff;
	font-family: "Poppins", cursive;
	margin-bottom: 1.5rem;
}
.form .joinTeamh1 {
	width: 100%;
}
.form .form-storyP {
	font-size: 1.9rem;
	color: #fff;
	margin-bottom: 1rem;
	font-family: "Poppins", cursive;
	text-align: center;
}
.form-svg {
	width: 170px;
	height: 170px;
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.traveller {
	background-image: url(../../Assets/Images/investor.svg);
}
.host {
	background-image: url(../../Assets/Images/electrical-engineer.svg);
}
.form-inputs {
	margin-bottom: 0.8rem;
	width: 80%;
	overflow: hidden;
}

.form-inputs p {
	font-size: 0.8rem;
	margin-top: 0.5rem;
	color: #f00e0e;
}

.form-label {
	display: inline-block;
	font-size: 1rem;
	margin-bottom: 6px;
	color: #fff;
}

.form-input {
	display: block;
	/* padding-left: 10px; */
	outline: none;
	border-radius: 2px;
	height: 40px;
	width: 100%;
	border: none;
}

.form-input::placeholder {
	color: #595959;
	font-size: 12px;
}

.form-input-login {
	font-size: 0.9rem;
	margin-top: 2rem;
	color: #fff;
	width: 80%;
	text-align: center;
}

.form-input-login a {
	text-decoration: none;
	color: #ff6b00;
	font-weight: 600;
}

#test5 {
	background-color: #ff6b00;
	margin-left: 2rem;
	margin-top: 0.1rem;
}

/*FORM TOGGLE*/
.hideForm {
	display: none;
}

.additional-question {
	color: white;
	font-family: "Poppins", Helvetica, sans-serif;
	letter-spacing: 0.05rem;
}

.link-span {
	color: #ff6b00;
	cursor: pointer;
}

/*TOGGLE BUTTON*/

input[type="checkbox"] {
	display: none;
}

#button {
	position: absolute;
	top: 35%;
	right: 70%;
	display: block;
	width: 291px;
	height: 125px;
	background-color: #000;
	border-radius: 350px;
	cursor: pointer;
	transform: scale(0.4);
	margin: 50px auto;
}

.privacy-and-terms {
	margin-top: 0rem;
	margin-bottom: 0.3rem;
	width: 90%;
}

#knob {
	width: 112px;
	height: 104px;
	background-image: url(../../Assets/Images/HeroBackground.jpeg);
	background-size: 701px;
	position: relative;
	top: 9px;
	left: 14px;
	border-radius: 290px;
	transition: 0.4s ease left, 0.4s ease background-position;
	z-index: 2;
}

#subscribe,
#alright {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 36px;
	font-weight: bold;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	margin-left: 171px;
	z-index: 1;
}

#alright {
	margin-left: 30px;
}

#lol-checkbox:checked + #button #knob {
	left: 170px;
	background-position: -350px 0;
}

.file-upload-div {
	margin: 10px;
	margin-left: 0;
	color: white;
	margin-top: 5px;
}
@media screen and (max-width: 768px) {
	.form-content-left {
		display: none;
	}
	.form-container {
		margin: 0 auto;
		width: 90vw;
		box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%);
		position: relative;
		opacity: 0.85;
		border-radius: 10px;
		height: 80vh;
		display: grid;
		grid-template-columns: 1fr;
		background: rgba(0, 0, 0, 0.089);
	}
	.form-wrapper {
		padding: 150px 0 30px;
	}
}
