.perk-item h3 {
	text-align: left;
	letter-spacing: 0px;
	margin-left: 1rem;
	font-weight: 300;
	font-family: "Poppins";
	width: 65%;
	letter-spacing: 1px;
}

.perk-item {
	border-right: 1px solid rgba(255, 255, 255, 0.4);
	padding: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffffdc;
}

.perk-grid {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.fa-lg {
	font-size: 2em !important;
}
