.gear-icon {
	color: rgb(0, 0, 0);
	cursor: pointer;
}

.profile-image-header {
	margin-top: 2%;
	width: 23%;
	height: 23%;
	margin-bottom: 3%;
}

.not-clickable {
	cursor: auto;
}

.profile-up .detail .icon {
	color: #ff6b00;
}
/* 
This below are changes to code after redesign at 22.10.2021,
please specify seletors with details */

.profile-wrapper .profile-up {
	flex-direction: column;
	width: 100%;
	justify-content: flex-start;

	justify-content: flex-start;
	background: white;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	margin-top: -12vh;
	z-index: 5;
	position: sticky;
	top: 2vh;
	margin-bottom: 2vh;
	padding-bottom: 5%;
}

.profile-wrapper .profile-container {
	display: grid;
	grid-template-columns: 2fr 5fr;
	width: 80%;
	padding-top: 0;
	align-items: start;
	min-height: 59.5vh;
}

.other-pages .profile-container {
	width: 80%;
	display: block;
}

.profile-wrapper .profile-right {
	width: 100%;
}

.profile-wrapper .profile-image-header {
	width: 50%;
	display: flex;
	align-content: center;
	justify-content: center;
	margin-top: 10%;
}

.profile-wrapper .project-card-container {
	width: 100%;
}

.profile-wrapper .profile-up .MuiBadge-root {
	width: 100%;
	height: 100%;
}

.profile-wrapper .profile-down {
	margin-left: 5%;
	z-index: 2;
}

.profile-wrapper .profile-down {
	width: 95%;
}

.profile-wrapper .profile-container .description {
	width: 100%;
}

.profile-wrapper .profile-right {
	margin-left: 0;
}

.profile-wrapper .MuiAvatar-root {
	box-shadow: -1px -1px 10px 2px rgb(0 0 0 / 30%);
	-webkit-box-shadow: -1px -1px 10px 2px rgb(0 0 0 / 30%);
	-moz-box-shadow: -1px -1px 10px 2px rgba(0, 0, 0, 0.3);
}

.profile-wrapper .profile-right .detail {
	margin-left: 10%;
}

.profile-wrapper .profile-right .welcomeTitle {
	text-align: center;
}

.profile-wrapper .available-projects-button {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width: 1000px) {
	.profile-wrapper .profile-container {
		grid-template-columns: 1fr;
	}
	.profile-wrapper .profile-container .description {
		margin-top: 5%;
	}

	.profile-wrapper .profile-down {
		margin-left: 0;
		width: 100%;
	}

	.profile-wrapper .profile-up {
		position: static;
	}

	.profile-wrapper .profile-right .detail {
		font-size: 2rem;
	}
	.profile-right .detail i {
		font-size: 2.8rem;
	}
}
/* THIS IS WHERE THESE CHANGES END */

@media only screen and (max-width: 500px) {
	.profile-image-header {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.profile-wrapper .profile-right .detail {
		font-size: 1.2rem;
	}
	.profile-right .detail i {
		font-size: 2rem;
	}
	.profile-wrapper .profile-container {
		width: 90%;
	}
}
