.terms-container {
	border-radius: 10px;
	margin-top: -12vh;
	z-index: 5;
	background: white;
	box-shadow: 0px 0px 20px rgb(0 0 0 / 10%);
	padding: 5%;
	text-align: justify;
	margin-bottom: 5vh;
}

.terms-wrapper .profile-container {
	grid-template-columns: 1fr !important;
}

.terms-of-use-bullet {
	margin-left: 5%;
	list-style-type: none;
}

.MuiTypography-gutterBottom {
	margin-top: 0.5em;
}
