.footer-container {
	background-color: #1c2237;
	padding: 2rem 0 2rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	bottom: 0;
	/* margin-top: 5rem; */
}

.footer-subscription {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-bottom: 15px;
	padding: 15px;
	color: #fff;
	min-height: 10.1vh;
}

.footer-subscription > p {
	font-family: "Trebuchet MS", Arial, sans-serif;
}

.footer-subscription-heading {
	margin-bottom: 14px;
	font-size: 24px;
}

.footer-subscription-text {
	margin-bottom: 24px;
	font-size: 20px;
}

.footer-input {
	padding: 6px 20px;
	border-radius: 2px;
	margin-right: 10px;
	outline: none;
	border: none;
	font-size: 18px;
	margin-bottom: 16px;
	border: 1px solid #fff;
}

.footer-links {
	width: 100%;
	max-width: 1000px;
	display: flex;
	justify-content: center;
	display: none;
}

.footer-link-wrapper {
	display: flex;
}

.footer-link-items {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 16px;
	text-align: center;
	width: 190px;
	box-sizing: border-box;
}

.footer-link-items h2 {
	margin-bottom: 16px;
}

.footer-link-items > h2 {
	color: #fff;
}

.footer-link-items a {
	color: #fff;
	text-decoration: none;
	margin-bottom: 0.5rem;
}

.footer-link-items a:hover {
	color: #ff6b00;
	transition: 0.3s ease-out;
}

.footer-email-form h2 {
	margin-bottom: 2rem;
}

.footer-input::placeholder {
	color: #b1b1b1;
}

/* Social Icons */
.social-icon-link {
	color: #fff;
	font-size: 24px;
}

.social-media {
	max-width: 1000px;
	width: 100%;
}

.social-media-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin: 2px auto 0 auto;
}

.social-icons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 240px;
}

.social-logo {
	color: #fff;
	justify-self: start;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.footer-logo .footer-logo-div {
	background: url("../../Assets/Images/solar-logo-footer.svg");
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.website-rights {
	color: #fff;
	margin-bottom: 16px;
	font-size: large;
}

@media screen and (max-width: 820px) {
	.footer-links {
		padding-top: 2rem;
	}

	.footer-input {
		width: 100%;
	}

	.footer-container .btn {
		width: 100%;
	}

	.footer-link-wrapper {
		flex-direction: column;
	}

	.social-media-wrap {
		flex-direction: column;
	}
}

@media only screen and (max-width: 768px) {
	.footer-link-items {
		width: 90%;
	}
}
