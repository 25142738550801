.profile-container {
	margin: 0 auto;
	width: 100%;
	/* box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 7px 20px 0 rgb(0 0 0 / 20%); */
	border-radius: 10px;
	/* background: rgba(0, 0, 0, 0.089); */
	display: flex;
	flex-direction: column;
	padding-top: 80px;
}

.available-projects-container {
	width: 70%;
	margin: 0 auto;
	margin-top: 15vh;
}
.profile-up {
	/* height: 21rem; */
	width: 70%;
	margin: 0.5rem auto;
	/* border-bottom: 1px solid gray; */
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile-left {
	width: 30%;
	position: relative;
	padding: 30px;
	box-sizing: border-box;
}

.available-project-overview-container {
}

/* .userTitle::before {
	position: absolute;
	left: 39%;
	transform: rotate(19deg);
	content: "User";
	color: orange;
	text-align: center;
	width: 4.6rem;
	height: 2rem;
	background: #fff;
	z-index: 1;
	font-size: 1.3rem;
} */

.hostTitle::before {
	position: absolute;
	left: 39%;
	transform: rotate(19deg);
	content: "Host";
	color: orange;
	text-align: center;
	width: 4.6rem;
	height: 2rem;
	background: #fff;
	z-index: 1;
	font-size: 1.3rem;
}
.profile-image {
	border-radius: 20%;
	width: 50%;
	height: 80%;
	box-shadow: 5px 5px 15px 5px rgb(0 0 0 / 58%);
}
.profile-right {
	width: 40%;
	margin-left: 10%;
	padding: 30px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.profile-right .welcomeTitle {
	margin-bottom: 1rem;
}
.profile-down {
	width: 100%;
}
.profile-activitiesTitle {
	text-align: left;
}

.profile-wrapper {
	/* background: rgba(211, 211, 211, 0.384); */
	/* background-position: center; */
	/* padding: 10px; */
	/* background: url("../images/backgroundo5.svg"); */
	width: 100%;
}

.profile-details {
	margin-top: 1rem;
	/* max-width: 50%; */
}

.profile-card-details {
	grid-template-columns: 1fr;
	text-align: left;
}
.profile-name {
	margin: 1rem 0;
}

.hostOptions {
	width: 50%;
	margin: 1rem auto 0 auto;
	margin-bottom: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.profile-right .detail {
	display: grid;
	grid-template-columns: 1fr 20fr;
	align-content: center;
	color: rgb(10, 10, 10);
	padding: 1.5%;
	font-size: 1.1rem;
}

.profile-right .detail .text {
	display: flex;
	align-items: center;
}

.profile-right .detail .text h4 {
	display: flex;
	align-items: center;
	padding-left: 5%;
	font-weight: 400;
}

.profile-right .detail .text .not-defined {
	font-weight: 300;
	cursor: pointer;
}
.profile-right .detail i {
	font-size: 1.4rem;
	display: flex;
}

.profile-right .detail .icon {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.MuiTypography-h4 {
	margin-top: 0.7em !important;
}

@media only screen and (max-width: 768px) {
	.profile-up {
		width: 90%;
	}
}

@media only screen and (max-width: 500px) {
	.profile-up {
		flex-direction: column;
	}

	.profile-right {
		width: 100%;
		text-align: center;
	}

	.profile-right .detail .text h4 {
		text-align: left;
	}
}
